import React, { createContext, useContext, useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

const IndustryContext = createContext();

export function useIndustry() {
  return useContext(IndustryContext);
}

export function IndustryProvider({ children }) {
    const [currentIndustry, setCurrentIndustry] = useState(null);
    const [availableIndustries, setAvailableIndustries] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      fetchIndustries();
    }, []);
  
    const fetchIndustries = async () => {
      try {
        const result = await client.graphql({
          query: `
            query ListIndustries {
              listIndustries(filter: { status: { eq: "ACTIVE" } }) {
                items {
                  id
                  name {
                    language
                    text
                  }
                  handle
                  domain
                  primaryColor
                  secondaryColor
                  logo {
                    fileName
                  }
                  status
                }
              }
            }
          `
        });
  
        const industries = result.data.listIndustries.items;
        setAvailableIndustries(industries);
  
        const hostname = window.location.hostname.toLowerCase();
        const matchingIndustry = industries.find(ind => hostname.includes(ind.domain));
        
        if (matchingIndustry) {
          setCurrentIndustry(matchingIndustry);
          applyTheme(matchingIndustry);
        } else {
            // If localhost use cp industry
            const cpIndustry = industries.find(ind => ind.handle === 'cp');
            setCurrentIndustry(cpIndustry);
            applyTheme(cpIndustry);
        }
      } catch (error) {
        console.error('Error loading industries:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const applyTheme = (industry) => {
        if (!industry) return;
        // Remove all classes from document.body that contain 'theme'
        document.body.className = document.body.className.split(' ').filter(c => !c.includes('theme')).join(' ');
        
        // Add new theme class if handle exists
        if (industry.handle) {
          document.body.classList.add(`${industry.handle}-theme`);
        }
      };
  
    const switchIndustry = (industry) => {
      setCurrentIndustry(industry);
      applyTheme(industry);
      // Optional: Save preference to localStorage
      localStorage.setItem('preferredIndustry', industry.id);
    };
  
    return (
      <IndustryContext.Provider value={{ 
        currentIndustry, 
        availableIndustries,
        switchIndustry,
        loading 
      }}>
        {children}
      </IndustryContext.Provider>
    );
  }