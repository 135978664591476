import { useState, useEffect, useContext, useRef, useLayoutEffect } from "react"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBIcon, MDBInput, MDBRow, MDBStepper, MDBStepperStep, MDBTypography, MDBValidationItem } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { UserContext } from "../../../App";
import { isoToGermanDate } from "../../../utils/dateTools";
import getEventDays from "../../../utils/getEventDays";
import { getEvent as getEventQuery } from "../../../graphql/queries";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../../utils/debounce";
import EventAndTicket from "./book-ticket-components/EventAndTicket";
import Visit from "./book-ticket-components/Visit";
import PersonalInformation from "./book-ticket-components/PersonalInformation";
import Confirmation from "./book-ticket-components/Confirmation";

import { createTicket as createTicketMutation } from "../../../graphql/mutations";
import { updateUser as updateUserMutation } from "../../../graphql/mutations";

import { fullAddressSchema, visitSchema } from "../../../validation/schemas/generic";
import { hotjar } from "react-hotjar";



export default function BookTicket() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const client = generateClient();
  const { t, i18n } = useTranslation()

  const [event, setEvent] = useState(null);
  const [eventDays, setEventDays] = useState([]);
  const [params, setParams] = useState({

    selectedDays: [],

  });
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  // User selections
  const [personalAddress, setPersonalAddress] = useState({});
  const [useProfileAddressForBilling, setUseProfileAddressForBilling] = useState(true);
  const [billingAddress, setBillingAddress] = useState({});

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [defaultStep, setDefaultStep] = useState(1);

  const handleClickNext = () => {
    nextRef.current.click();
  }

  const handleClickPrev = () => {
    prevRef.current.click();
  }

  useEffect(() => {
    if(user) {
      setPersonalAddress({
        salutation: user.salutation,
        title: user.title,
        firstName: user.firstName,
        lastName: user.lastName,
        street: user.contact?.address?.street || "",
        streetNumber: user.contact?.address?.streetNumber || "",
        zip: user.contact?.address?.zip || "",
        city: user.contact?.address?.city || "",
        countryCode: user.contact?.address?.countryCode || "",
        company: user.contact?.address?.company || ""
      });
    }
  }, [user]);



  // URL parameters
  const [paramsInitialized, setParamsInitialized] = useState(false);
  const [routerParams, setRouterParams] = useSearchParams();
  const extractExistingParams = (searchParams) => {
      return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
          if (value === 'true') {
              acc[key] = true;
          } else if (value === 'false') {
              acc[key] = false;
          } else {
              acc[key] = value;
          }
          return acc;
      }, {});
  }
  useEffect(() => {
    console.log("setParams", extractExistingParams(routerParams))
    if(!paramsInitialized) {
      const paramsQuery = {...extractExistingParams(routerParams), selectedDays: extractExistingParams(routerParams).selectedDays?.length > 0 ? extractExistingParams(routerParams).selectedDays.split(",") : []};
      setParams(paramsQuery);
      setParamsInitialized(true);
      if(paramsQuery.eventId && paramsQuery.ticketType) {
        setTimeout(() => {
          nextRef.current?.click();

        }, 1100);
      }

    }
    
  }, [routerParams]);
  // Update URL parameters when user selections change
  useEffect(() => {
    console.log("setRouterParams", params)
    if(paramsInitialized) {
      setRouterParams(({
            ...params,
            selectedDays: params.selectedDays.join(",")
        }));
    }
    //   
  }, [params, paramsInitialized]);

  // Fetch event
  const getEvent = async () => {
      if(params.eventId) {
          try {
            const eventData = await client.graphql({
              query: getEventQuery,
              variables: { id: params.eventId } });
              setEvent(eventData.data.getEvent);
              setEventDays(getEventDays(eventData.data.getEvent));
          } catch (error) {
            console.error(error);
            
          }
      }
  }
  useEffect(() => {
      getEvent().then(() => setTimeout(() => {
        setIsLoading(false)
      }, 1000))
  }, [params.eventId]);


  // Create ticket booking
  const createTicket = async () => {
    if(!user.id) {
      hotjar.event("Book ticket without user data");
    }
      try {
        const ticketData = await client.graphql({
          query: /* GraphQL */ `
          mutation CreateTicket(
            $input: CreateTicketInput!
            $condition: ModelTicketConditionInput
          ) {
            createTicket(input: $input, condition: $condition) {
              id
              type
              invitationCode
              orderNumber
              billingEmail
              createdAt
              updatedAt
              eventDays
              userTicketsId
              ticketEventId
              ticketCompanyId
              ticketPaymentId
              __typename
            }
          }
        `,
          variables: {
            input: {
              type: params.ticketType,
              userTicketsId: user.id,
              createdByUserId: user.id,
              addons: params.createCompanyProfile ? ["company-profile"] : [], 
              eventDays: params.selectedDays, 
              ticketEventId: params.eventId,
              invitationCode: params.invitationCode,
              personalAddress: personalAddress,
              firstName: personalAddress.firstName,
              lastName: personalAddress.lastName,
              companyName: personalAddress.company,
              billingAddress: useProfileAddressForBilling ? null : billingAddress
            }
          }
        });
        console.log("Created Ticket");
        //console.log(ticketData);
      } catch (error) {
        console.error(error);
        setErrors(old => [...old, t("Unable to create ticket. Please contact support.")]);
        throw error;
      }
  }

  const updateUserPersonalAddress = async () => {
    try {
      const personalAddressForUser = {...personalAddress};
      delete personalAddressForUser.salutation;
      delete personalAddressForUser.title;
      delete personalAddressForUser.firstName;
      delete personalAddressForUser.lastName;

      const userData = await client.graphql({
        query: updateUserMutation,
        variables: {
          input: {
            id: user.id,
            contact: {
              address: personalAddressForUser
            }
          }
        }
      });
      console.log("Updated User Address")
      //console.log(userData);
    } catch (error) {
      console.error(error);
    }
  }

  const hasUserPersonalAddressChanged = () => {
    return user.contact?.address.street !== personalAddress.street ||
    user.contact?.address.streetNumber !== personalAddress.streetNumber ||
    user.contact?.address.zip !== personalAddress.zip ||
    user.contact?.address.city !== personalAddress.city ||
    user.contact?.address.countryCode !== personalAddress.countryCode ||
    user.contact?.address.company !== personalAddress.company
  }

  // Handle booking
  const [bookingInProgress, setBookingInProgress] = useState(false);
  const handleBookTicket = async () => {

    if(validations?.visit?.length > 0 || validations?.personalAddress?.length > 0 || (!useProfileAddressForBilling && validations?.billingAddress?.length > 0)) {
      setErrors([t("Please fill out all required fields."), ...validations?.visit, ...validations?.personalAddress, ...(useProfileAddressForBilling ? [] : validations?.billingAddress)].filter(Boolean));
      return;
    }

    try {
      setBookingInProgress(true);
      if(hasUserPersonalAddressChanged()) {
        updateUserPersonalAddress();
      }
      await createTicket();
      setTimeout(() => {
        setBookingInProgress(false);
        navigate(`/bookings`);
      }, 1000);
    } catch (error) {
      console.error(error);
      setBookingInProgress(false);
    }
  }


  // Validation
  const [validations, setValidations] = useState({});
  const [showValidations, setShowValidations] = useState({
    visit: false,
    personalAddress: false,
    billingAddress: false
  });

  const debouncedPersonalAddress = useDebounce(personalAddress, 1000);
  const debouncedBillingAddress = useDebounce(billingAddress, 1000);
  const debouncedParams = useDebounce(params, 500);

  useEffect(() => {
    const personalProfile = fullAddressSchema.validate(debouncedPersonalAddress).map((validation) => validation.path);
    const billingProfile = fullAddressSchema.validate(debouncedBillingAddress).map((validation) => validation.path);
    const visit = visitSchema.validate({...debouncedParams}).map((validation) => validation.path);
    setValidations({personalAddress: personalProfile, billingAddress: billingProfile, visit});
  }, [debouncedPersonalAddress, debouncedBillingAddress, debouncedParams]);

  return (
    !isLoading &&
  <>
  <MDBTypography tag='h1' className="text-center">{t("Book ticket")}</MDBTypography>
       <div className="d-none">
       <MDBBtn ref={prevRef}>{t("Previous")}</MDBBtn>
        <MDBBtn ref={nextRef}>{t("Next")}</MDBBtn>
       </div>
<MDBCard className="mt-lg-4">
    <MDBCardBody>


    
      <MDBStepper  defaultStep={defaultStep} externalNext={nextRef} externalPrev={prevRef} type="horizontal" className="text-center">
      <MDBStepperStep itemId={1} headText={t("Select event and ticket")} headIcon={<MDBIcon fas icon="calendar-alt" />} >
        <EventAndTicket
          event={event}
          params={params}
          setParams={setParams}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          />
      </MDBStepperStep>
      <MDBStepperStep itemId={2} headText={t("Your visit")} headIcon={<MDBIcon fas icon="calendar-day" />}>
        <Visit
          params={params}
          setParams={setParams}
          eventDays={eventDays}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          validations={validations}
          showValidations={showValidations}
          setShowValidations={setShowValidations}
          />
      </MDBStepperStep>
      <MDBStepperStep itemId={3} headText={t("Personal information")} headIcon={<MDBIcon fas icon="user" />}>
        <PersonalInformation
          user={user}
          params={params}
          setParams={setParams}
          useProfileAddressForBilling={useProfileAddressForBilling}
          setUseProfileAddressForBilling={setUseProfileAddressForBilling}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          personalAddress={personalAddress}
          setPersonalAddress={setPersonalAddress}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          validations={validations}
          showValidations={showValidations}
          setShowValidations={setShowValidations}
          />
      </MDBStepperStep>
      <MDBStepperStep itemId={4} headText={t("Confirmation")} headIcon={<MDBIcon fas icon="check" />}>
      <Confirmation
          user={user}
          event={event}
          eventId={params.eventId}
          params={params}
          personalAddress={personalAddress}
          useProfileAddressForBilling={useProfileAddressForBilling}
          setUseProfileAddressForBilling={setUseProfileAddressForBilling}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          handleBookTicket={handleBookTicket}
          bookingInProgress={bookingInProgress}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          validations={validations}
          showValidations={showValidations}
          setShowValidations={setShowValidations}
          errors={errors}
          />
      </MDBStepperStep>
    </MDBStepper>
    </MDBCardBody>
</MDBCard>
</>
  )
}
