// Lecture Type
// type Lecture
//   @model
//   @auth(rules: [
//     { allow: private, operations: [read] },
//     { allow: groups, groups: ["Admin, Moderator"], operations: [create, read, update, delete] }])
// {
//   id: ID!
//   startTime: AWSDateTime!
//   endTime: AWSDateTime!
//   event: Event @belongsTo
//   company: Company @hasOne
//   speakers: [Employee] @hasMany
//   session: String
//   room: String
//   title: [MultiLangString!]!
//   subjects: [MultiLangStringList]
//   content: MultiLangString
//   presentationLanguage: String
//   image: Image
//   pdfDe: File
//   pdfEn: File
// }

import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isoToGermanDate } from "../../../utils/dateTools";
import moment from 'moment-timezone';
const client = generateClient();
export default function AllLectures() {
    const { t, i18n } = useTranslation();

    const companyid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[2]
    : window.location.pathname.split("/")[3];

    const [lectures, setLectures] = useState([]);

    const getLectures = async () => {
        try {
            const lectures = await client.graphql({
                variables: {
                    limit: 10000,
                    filter: {
                        companyLecturesId: {
                          eq: companyid
                        },
                        createdAt: {
                            gt: "2023-10-01T00:00:00.000Z"
                        },
                        type: {
                            matchPhrasePrefix: "promotion"
                        }            
                    }
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                      id
                      title {
                        language
                        text
                      }
                      topics {
                        items {
                          topic {
                            id
                            name {
                              language
                              text
                            }
                          }
                        }
                    }  
                      speakers {
                        items {
                            employee {
                                image {
                                  fileName
                                  alt
                                }
                              }
                        }
                      }
                      event {
                        name {
                          language
                          text
                        }
                        id
                      }
                      event {
                        id
                        name {
                          language
                          text
                        }
                      }
                      type
                      session
                      room
                      startTime
                      endTime
                      dateTimes
                      companyBooth
                      presentationLanguage
                      createdAt
                      updatedAt
                      companyLecturesId
                      eventLecturesId
                      lectureMainSpeakerId
                      __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            console.log(lectures.data.searchLectures.items)
            setLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})));
        } catch (error) {
            console.log("error on fetching lectures", error);
            if(error.data?.searchLectures?.items) setLectures(error.data.searchLectures.items);
        }
    };

    useEffect(() => {
        getLectures();
    }, []);


    const [deleteLectureModal, setDeleteLectureModal] = useState(false);
    const toggleDeleteLectureModal = () => setDeleteLectureModal(!deleteLectureModal);

    const [selectedLectureRemove, setSelectedLectureRemove] = useState(null);

    const deleteLecture = async (id) => {
        try {
            const deletedLecture = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteLecture(
                  $input: DeleteLectureInput!
                  $condition: ModelLectureConditionInput
                ) {
                  deleteLecture(input: $input, condition: $condition) {
                    id
                  }
                }
              `
            });
            console.log("deleted lecture", deletedLecture);
            setLectures(lectures.filter((lecture) => lecture.id !== id));
            setSelectedLectureRemove(null);
            toggleDeleteLectureModal();
        } catch (error) {
            console.log("error on deleting lecture", error);
        }
    }



  return (
<>

<MDBRow className="mb-3 mb-lg-5">
    <MDBCol size="12" lg="5">
        <MDBCard className="mx-auto text-center">
            <MDBCardBody>
                <MDBCardTitle>
                    <h2>{t("Lounges 2025 Karsruhe")}</h2>
                </MDBCardTitle>
                <h5>{t("Register Promotion for Lounges 2025 Karsruhe now.")}</h5>
                <Link to={`/mycompany/${companyid}/register-action?eventId=81f8ba3d-61b2-48c0-8d99-d2b785920450`}>
                    <MDBBtn>{t("Register Promotion")}</MDBBtn>
                </Link>
            
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    {/* <MDBCol size="12" lg="4">
        <MDBCard className="mx-auto text-center h-100" style={{backgroundColor: "#c0c0c0"}}>
            <MDBCardBody>
                <MDBCardTitle>
                    <h2>{t("Lounges 2025 Karsruhe")}</h2>
                </MDBCardTitle>
                <h5>{t("Registration will start from October 2024")}</h5> */}
                {/* <Link to={`/mycompany/${companyid}/register-lecture`}>
                    <MDBBtn>{t("Register Lecture")}</MDBBtn>
                </Link> */}
{/*             
            </MDBCardBody>
        </MDBCard>
    </MDBCol> */}
</MDBRow>



<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>
            <h2>{t("Promotions")}</h2>
        </MDBCardTitle>
        <MDBTable>
            <MDBTableHead>
                <tr>
                <th>{t("Event")}</th>
                <th>{t("Type")}</th>
                    <th>{t("Title")}</th>
                    <th>{t("Speakers")}</th>
                    <th>{t("Date and Time")}</th>
                    <th>{t("Location")}</th>
                    <th>{t("Actions")}</th>

                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {lectures.length > 0 ? 
                    lectures.map((lecture) => (
                        <tr key={lecture.id}>
                        <td>{lecture.event?.name?.find(n => n.language === i18n.language)?.text || lecture.event?.name?.[0]?.text}</td>
                            <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                            <td>{t(lecture.type)}</td>
                            <td>{lecture.speakers?.items?.map(speaker => <>
                            {speaker?.employee?.image?.fileName ?
                            <img
                                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                }}
                                alt={speaker.employee?.image.alt}
                            />
                            :
                            <MDBIcon
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    textAlign: "center",
                                    paddingTop: "12px",
                                    fontSize: "20px",
                                }}
                                fas
                                icon="user-alt"
                            />
                            }
                            </>)}</td>
                            <td>
                              {lecture.dateTimes && lecture.dateTimes.length > 0 ? 
                                lecture.dateTimes.map(dateTime => {
                                  // Konvertiere das ISO-Datum in die deutsche Zeitzone
                                  const dateInGermany = moment(dateTime).tz("Europe/Berlin");
                                  return (
                                    <>
                                      {isoToGermanDate(dateInGermany.format())} - {dateInGermany.format('HH:mm')} Uhr
                                      <br />
                                    </>
                                  );
                                })
                                : "noch nicht festgelegt"}
                            </td>
                            <td>{lecture.companyBooth || t("not yet determined")}</td>
                            
                            <td>
                            <MDBBtnGroup shadow="0">
                            {/* <MDBBtn color='link' style={{color: "black"}} className="px-2" >{t("Upload Documents")}</MDBBtn> */}
                            <Link to={`/mycompany/${companyid}/register-action/${lecture.id}`}>
                                <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                            </Link>
                            <MDBBtn color='link' style={{color: "red"}} className="px-2" onClick={() => {setSelectedLectureRemove(lecture.id); toggleDeleteLectureModal()}}>{t("Delete")}</MDBBtn>
                            </MDBBtnGroup>
                            </td>
                        </tr>
                    ))
                :
                    <tr>
                        <td colSpan="7">
                            {t("No promotions found")}
                        </td>
                    </tr>
                }
            </MDBTableBody>
        </MDBTable>
    </MDBCardBody>
</MDBCard>
<MDBModal open={deleteLectureModal} onClose={() => setDeleteLectureModal(false)}>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Remove promotion")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteLectureModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
            <p>{t("Are you sure you want to remove this promotion?")}</p>
            <p className="fw-bold">{selectedLectureRemove && (lectures.find(l => l.id === selectedLectureRemove)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureRemove).title[0].text)}</p>
            <MDBBtn color='danger' onClick={() => deleteLecture(selectedLectureRemove)}>{t("Remove")}</MDBBtn>
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
