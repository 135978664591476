import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { uploadData, getUrl, remove } from 'aws-amplify/storage';
import { useTranslation } from 'react-i18next';
import { MDBBtn, MDBCard, MDBCardBody, MDBInput, MDBTextArea, MDBSpinner, MDBTypography, MDBRow, MDBCol, MDBFile, MDBInputGroup, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBTable, MDBTableHead, MDBTableBody, MDBSelect, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { getLocalizedText } from "../../utils/localizedText";

const EditEvent = () => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const client = generateClient();
    
    const [event, setEvent] = useState({});
    const [floorPlanFile, setFloorPlanFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [uploadingImageError, setUploadingImageError] = useState(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [uploadingFileError, setUploadingFileError] = useState(null);

    const [industries, setIndustries] = useState([]);
    const [selectedIndustryIds, setSelectedIndustryIds] = useState([]);

    const [activeTab, setActiveTab] = useState('general');

  useEffect(() => {
    if (eventId) {
      fetchEvent();
    }
  }, [eventId]);

  const fetchEvent = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: `
          query GetEvent($id: ID!) {
            getEvent(id: $id) {
              id
              name {
                language
                text
              }
              description {
                language
                text
              }
              bookingNotice {
                language
                text
              }
              image {
                fileName
              }
              status
              startDate
              endDate
              lectureRegistrationStartDate
              lectureRegistrationEndDate
              deadlineBoothEquipmentPrint
              startBoothEquipmentBooking
              deadlineBoothEquipmentBooking
              landingPageUrl
              lecturesNotice {
                language
                text
              }
              floorPlan {
                fileName
                s3Path
              }
              availableLectureTypes
              industries {
                items {
                  industry {
                    id
                  }
                }
              }
            }
          }
        `,
        variables: { id: eventId },
      });
      setEvent(response.data.getEvent);
      setSelectedIndustryIds(response.data.getEvent.industries.items.map(item => item.industry.id));
    } catch (error) {
      console.error('Error fetching event:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIndustries = async () => {
    try {
      const response = await client.graphql({
        query: `
          query ListIndustries {
            listIndustries {
              items {
                id
                name {
                  language
                  text
                }
                handle
              }
            }
          }
        `
      });
      setIndustries(response.data.listIndustries.items);
    } catch (error) {
      console.error('Error fetching industries:', error);
    }
  };

  useEffect(() => {
    fetchIndustries();
  }, []);

  const handleInputChange = (field, value, language = null) => {
    setEvent(prev => {
      if (language) {
        const updatedField = prev[field] ? [...prev[field]] : [];
        const index = updatedField.findIndex(item => item.language === language);
        if (index !== -1) {
          updatedField[index] = { ...updatedField[index], text: value };
        } else {
          updatedField.push({ language, text: value });
        }
        return { ...prev, [field]: updatedField };
      } else {
        return { ...prev, [field]: value };
      }
    });
  };

  const handleFloorPlanChange = (e) => {
    const file = e.target.files[0];
    setFloorPlanFile(file);
  };

  const uploadFloorPlan = async () => {
    if (!floorPlanFile) return null;

    try {
      const fileName = `floorplan_${Date.now()}_${floorPlanFile.name}`;
      const result = await uploadData({
        key: fileName,
        data: floorPlanFile,
        options: {
          accessLevel: 'public',
        }
      }).result;

      const fileUrl = await getUrl({
        key: fileName,
        options: {
          accessLevel: 'public',
          validateObjectExistence: true
        }
      });

      return {
        fileName: fileName,
        s3Path: fileUrl.url,
      };
    } catch (error) {
      console.error('Error uploading floor plan:', error);
      return null;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let updatedEvent = { ...event };
      if (floorPlanFile) {
        const uploadedFloorPlan = await uploadFloorPlan();
        if (uploadedFloorPlan) {
          updatedEvent.floorPlan = uploadedFloorPlan;
        }
      }
  
      // Speichere zuerst das Event
      let savedEventId;
      if (eventId) {
        await updateEvent(updatedEvent);
        savedEventId = eventId;
      } else {
        const result = await createEvent(updatedEvent);
        savedEventId = result.data.createEvent.id;
      }
  
      // Verwalte die Industry-Verknüpfungen
      if (eventId) {
        // Hole existierende Industries
        const existingIndustries = await client.graphql({
          query: `query ListIndustryEvents($filter: ModelIndustryEventsFilterInput) {
            listIndustryEvents(filter: $filter) {
              items {
                id
                industryId
              }
            }
          }`,
          variables: {
            filter: { eventId: { eq: eventId } }
          }
        });
  
        const existingIndustryIds = existingIndustries.data.listIndustryEvents.items.map(item => item.industryId);
  
        // Bestimme hinzuzufügende und zu entfernende Industries
        const industriesToAdd = selectedIndustryIds.filter(id => !existingIndustryIds.includes(id));
        const industriesToRemove = existingIndustries.data.listIndustryEvents.items
          .filter(item => !selectedIndustryIds.includes(item.industryId));
  
        // Entferne alte Verknüpfungen
        await Promise.all(industriesToRemove.map(async item => {
          await client.graphql({
            query: `mutation DeleteIndustryEvents($input: DeleteIndustryEventsInput!) {
              deleteIndustryEvents(input: $input) {
                id
              }
            }`,
            variables: {
              input: { id: item.id }
            }
          });
        }));
  
        // Füge neue Verknüpfungen hinzu
        await Promise.all(industriesToAdd.map(async industryId => {
          await client.graphql({
            query: `mutation CreateIndustryEvents($input: CreateIndustryEventsInput!) {
              createIndustryEvents(input: $input) {
                id
              }
            }`,
            variables: {
              input: {
                eventId: savedEventId,
                industryId: industryId
              }
            }
          });
        }));
      } else {
        // Bei einem neuen Event füge alle ausgewählten Industries hinzu
        await Promise.all(selectedIndustryIds.map(async industryId => {
          await client.graphql({
            query: `mutation CreateIndustryEvents($input: CreateIndustryEventsInput!) {
              createIndustryEvents(input: $input) {
                id
              }
            }`,
            variables: {
              input: {
                eventId: savedEventId,
                industryId: industryId
              }
            }
          });
        }));
      }
  
      navigate('/admin/events');
    } catch (error) {
      console.error('Error saving event:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateEvent = async () => {
    let eventForUpdate = { ...event };
    delete eventForUpdate.industries;
    await client.graphql({
      query: `
        mutation UpdateEvent($input: UpdateEventInput!) {
          updateEvent(input: $input) {
            id
          }
        }
      `,
      variables: { input: { id: eventId, ...eventForUpdate } },
    });
  };

  const createEvent = async () => {
    return await client.graphql({
      query: `
        mutation CreateEvent($input: CreateEventInput!) {
          createEvent(input: $input) {
            id
          }
        }
      `,
      variables: { input: event },
    });
  };

  const handleSetImage = (file) => {
    if(file) {
      try {
        setIsUploadingImage(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onerror = error => {
        console.log('Error reader: ', error);
        setUploadingImageError(error);
      }
      reader.onload = async () => {
        try {
          console.log(reader.result)
          const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
          const response = await client.graphql({
            query: /* GraphQL */ `
            mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
              uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
            }
          `,
            variables: {
              imageBuffer: base64FileBuffer,
              imageType: "eventImage",
            },
          });
          console.log("response", response);
          const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
          setEvent(oldData => ({...oldData, image: {fileName}}));
          
          } catch (error) {
            console.log("error", error);
            setIsUploadingImage(false);
            setUploadingImageError(error.errors[0].message);
          }
      }
      setIsUploadingImage(false);
      setUploadingImageError(null);
      } catch (error) {
        console.log("error", error);
        setIsUploadingImage(false);
        setUploadingImageError(error.errors[0].message);
      }
    }
  }

  const handleSetFile = (file) => {
  if(file) {
    try {
    const fileName = file.name;
      console.log("filename", fileName);
      setIsUploadingFile(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => {
      console.log('Error: ', error);
      setUploadingFileError(error);
    }
    reader.onload = async () => {
     try {
      const result = await uploadData({
        key: "eventFiles/" + fileName,
        data: file,
      }).result;
      console.log('Succeeded: ', result);
      const fileURL = await getUrl({ key: "public/eventFiles/" + fileName});
      console.log('fileURL', fileURL); 
      setEvent(oldData => ({...oldData, floorPlan: {fileName: fileName, s3Path: "public/eventFiles/" + fileName}}));
      setIsUploadingFile(false);
      setUploadingFileError(null);
     } catch (error) {
      console.log("error", error);
      setIsUploadingFile(false);
      setUploadingFileError(error.errors?.[0].message || error.message);
     }
    };
    
    
    } catch (error) {
      console.log("error", error);
      setIsUploadingFile(false);
      setUploadingFileError(error.errors?.[0].message || error.message);
    }
    
  }
    
  }

  const downloadFile = async (path) => {

    const getUrlResult = await getUrl({
      key: path.replace('public/', ''),
    });
    console.log('signed URL: ', getUrlResult.url);
    window.open(getUrlResult.url, '_blank');
  }

  const removeFile = async (path) => {
    try {
      await remove({ key: path });
    setEvent(oldData => ({...oldData, floorPlan: null}));
    } catch (error) {
      console.log("error", error);
    }
  }


  // Products Tab

  const [isLoadingSaveEventProducts, setIsLoadingSaveEventProducts] = useState(false);
  const [eventProducts, setEventProducts] = useState([]);
  const [eventProductsOriginal, setEventProductsOriginal] = useState([]);
  const [eventProductsTypeFilter, setEventProductsTypeFilter] = useState(null);
  const [products, setProducts] = useState([]);
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const getEventProducts = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        query ListEventProducts(
          $filter: ModelEventProductFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listEventProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              event {
                id 
              }
              product {
                id
                sku
                name {
                  language
                  text
                }
                ean
                type
                subType
                price
                allowedBuyerGroups
                icon
                stock
              }
              price
              stock
              isAvailable
              createdAt
              updatedAt
              productEventProductsId
              eventEventProductsId
              __typename
            }
            nextToken
            __typename
          }
        }
      `,
        variables: {
          filter: {
            eventEventProductsId: {
              eq: eventId,
            },
          },
        },
      });
      setEventProducts(response.data.listEventProducts.items);
      setEventProductsOriginal(response.data.listEventProducts.items);
    } catch (error) {
      console.error('Error fetching event products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProducts = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        query ListProducts(
          $filter: ModelProductFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              sku
              name {
                language
                text
              }
              ean
              type
              subType
              price
              downPaymentPrice
              allowedBuyerGroups
              requiredShipping
              icon
              stock
              createdAt
              updatedAt
              bundleBoothAvaiableAccessoriesId
              bundleBoothIncludedAccessoriesId
              __typename
            }
            nextToken
            __typename
          }
        }
      `,
        variables: {
          filter: {
            type: {
              eq: 'event_equipment',
            }
          },
          limit: 1000,
        },
      });
      setProducts(response.data.listProducts.items);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addProductToEvent = async (productId) => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        mutation CreateEventProduct(
          $input: CreateEventProductInput!
        ) {
          createEventProduct(input: $input) {
            id
            event {
              id 
            }
            product {
              id
              sku
              name {
                language
                text
              }
              ean
              type
              subType
              price
              allowedBuyerGroups
              icon
              stock
            }
            price
            stock
            isAvailable
            createdAt
            updatedAt
            productEventProductsId
            eventEventProductsId
            __typename
          }
        }
      `,
        variables: {
          input: {
            eventEventProductsId: eventId,
            productEventProductsId: productId,
          },
        },
      });
      setEventProducts(old => [...old, response.data.createEventProduct]);
    } catch (error) {
      console.error('Error adding product to event:', error);
    }
  };

  const removeProductFromEvent = async (eventProductId) => {
    try {
      await client.graphql({
        query: /* GraphQL */ `
        mutation DeleteEventProduct(
          $input: DeleteEventProductInput!
        ) {
          deleteEventProduct(input: $input) {
            id
          }
        }
      `,
        variables: {
          input: {
            id: eventProductId,
          },
        },
      });
      setEventProducts(old => old.filter(product => product.id !== eventProductId));
    } catch (error) {
      console.error('Error removing product from event:', error);
    }
  };





  const handleEventProductChange = (eventProductId, field, value) => {
    setEventProducts(old => old.map(product => {
      if(product.id === eventProductId) {
        return { ...product, [field]: value };
      }
      return product;
    }));
  };

  const handleSaveEventProducts = async () => {
    setIsLoadingSaveEventProducts(true);
    try {
      const updatedProducts = eventProducts.filter(product => {
        const originalProduct = eventProductsOriginal.find(p => p.id === product.id);
        return (product.price || product.stock) && ( product.price !== originalProduct.price || product.stock !== originalProduct.stock);
      });
      await Promise.all(updatedProducts.map(async product => {
        await client.graphql({
          query: /* GraphQL */ `
          mutation UpdateEventProduct(
            $input: UpdateEventProductInput!
          ) {
            updateEventProduct(input: $input) {
              id
              event {
                id
              }
              product {
                id
                sku
                name {
                  language
                  text
                }
                ean
                type
                subType
                price
                allowedBuyerGroups
                icon
                stock
              }
              price
              stock
              isAvailable
              createdAt
              updatedAt
              productEventProductsId
              eventEventProductsId
              __typename
            }
          }
        `,
          variables: {
            input: {
              id: product.id,
              price: product.price || 0,
              stock: product.stock || 0,
            },
          },
        });
      }));
      // Update the original products list
      setEventProductsOriginal(eventProducts);

    } catch (error) {
      console.error('Error saving event products:', error);
    } finally {
      setIsLoadingSaveEventProducts(false);
    }
  };

  // Carpet Tab

  const [isLoadingCarpet, setIsLoadingCarpet] = useState(false);
  const [eventCarpets, setEventCarpets] = useState([]);
  const [carpets, setCarpets] = useState([]);

  const getEventCarpets = async () => {
    setIsLoadingCarpet(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        query ListAvailableCarpetsInEvents(
          $filter: ModelAvailableCarpetsInEventsFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listAvailableCarpetsInEvents(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              id
              eventId
              eventCarpetId
              event {
                id
              }
              eventCarpet {
                id
                order
                name {
                  language
                  text
                }
                description {
                  language
                  text
                }
                color
                image {
                  fileName
                }
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
        }
      `,
        variables: {
          filter: {
            eventId: {
              eq: eventId,
            },
          },
        },
      });
      setEventCarpets(response.data.listAvailableCarpetsInEvents.items);
    } catch (error) {
      console.error('Error fetching event carpets:', error);
    } finally {
      setIsLoadingCarpet(false);
    }
  };

  const getCarpets = async () => {
    setIsLoadingCarpet(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        query ListEventCarpets(
          $filter: ModelEventCarpetFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listEventCarpets(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              order
              name {
                language
                text
              }
              description {
                language
                text
              }
              color
              image {
                fileName
              }
            }
            nextToken
            __typename
          }
        }
      `
      });
      setCarpets(response.data.listEventCarpets.items);
    } catch (error) {
      console.error('Error fetching carpets:', error);
    } finally {
      setIsLoadingCarpet(false);
    }
  };

  const addCarpetToEvent = async (carpetId) => {
    try {
      const res = await client.graphql({
        query: /* GraphQL */ `
        mutation CreateAvailableCarpetsInEvents(
          $input: CreateAvailableCarpetsInEventsInput!
        ) {
          createAvailableCarpetsInEvents(input: $input) {
            id
            eventId
            eventCarpetId
            event {
              id
            }
            eventCarpet {
              id
              order
              name {
                language
                text
              }
              description {
                language
                text
              }
              color
              image {
                fileName
              }
            }
            createdAt
            updatedAt
            __typename
          }
        }
      `,
        variables: {
          input: {
            eventId,
            eventCarpetId: carpetId,
          },
        },
      });
      setEventCarpets(old => [...old, res.data.createAvailableCarpetsInEvents]);
    } catch (error) {
      console.error('Error adding carpet to event:', error);
    }
  };

  const removeCarpetFromEvent = async (availableCarpetId) => {
    try {
      await client.graphql({
        query: /* GraphQL */ `
        mutation DeleteAvailableCarpetsInEvents(
          $input: DeleteAvailableCarpetsInEventsInput!
        ) {
          deleteAvailableCarpetsInEvents(input: $input) {
            id
          }
        }
      `,
        variables: {
          input: {
            id: availableCarpetId,
          },
        },
      });
      setEventCarpets(old => old.filter(carpet => carpet.id !== availableCarpetId));
    } catch (error) {
      console.error('Error removing carpet from event:', error);
    }
  };



  useEffect(() => {
    if(activeTab === 'products') {
      getEventProducts();
      getProducts();
    }
    if(activeTab === 'carpet') {
      getEventCarpets();
      getCarpets();
    }
  }, [activeTab]);

  if (isLoading ) {
    return <MDBSpinner />;
  }

  return (
<>
  <MDBCard>
    <MDBCardBody>
      <MDBTypography tag="h2" className="mb-4">
        {eventId ? t('Edit Event') : t('Create Event')}
      </MDBTypography>
      <MDBTabs className='mb-3'>
      <MDBTabsItem>
          <MDBTabsLink onClick={() => setActiveTab("general")} active={activeTab === 'general'}>
            {t("General")}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
        <MDBTabsLink onClick={() => setActiveTab("products")} active={activeTab === 'products'}>
          {t("Products")}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
        <MDBTabsLink onClick={() => setActiveTab("carpet")} active={activeTab === 'carpet'}>
          {t("Carpet")}
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

<MDBTabsContent>
  <MDBTabsPane open={activeTab === 'general'}>
{/* Name */}
<div>
<MDBTypography tag="h5">{t("Name")}</MDBTypography>
<MDBInputGroup className="mb-1">
  <div className="input-group-text">
  <ReactCountryFlag
      countryCode="de"
      svg
      style={{
      width: "20px",
      height: "20px",
      }}
      title={i18n.language}
  /> 
  </div>
  <MDBInput
  label={t("German")}
  type="text"
  maxLength="60"
  value={event?.name?.find((t) => t.language === "de")?.text || ""}
  onChange={(e) => setEvent(old => ({...old, name: [{language: "de", text: e.target.value}, ...(old.name?.length > 0 ? old.name.filter((t) => t.language !== "de") : [])]}))}
  />
</MDBInputGroup>
<MDBInputGroup className="mb-1">
  <div className="input-group-text">
  <ReactCountryFlag
      countryCode="gb"
      svg
      style={{
      width: "20px",
      height: "20px",
      }}
      title={i18n.language}
  />
  </div>
  <input
  className="form-control"
  placeholder={t("English")}
  type="text"
  maxLength="60"
  value={event?.name?.find((t) => t.language === "en")?.text || ""}
  onChange={(e) => setEvent(old => ({...old, name: [{language: "en", text: e.target.value}, ...(old.name?.length > 0 ? old.name.filter((t) => t.language !== "en") : [])]}))}
  />
</MDBInputGroup>
</div>
{/* Name End */}
<MDBTypography tag="h5" className="mt-4">{t("Industries")}</MDBTypography>
<MDBSelect
  label={t("Select Industries")}
  multiple
  data={industries.map(industry => ({
    text: getLocalizedText(industry.name),
    value: industry.id
  }))}
  value={selectedIndustryIds}
  onChange={(entries) => setSelectedIndustryIds(entries.map(entry => entry.value))}
/>
{/* Description */}
<MDBRow className="mb-4">
    
    <MDBCol md="6">
    <ReactCountryFlag
countryCode="de"
svg
style={{
width: "20px",
height: "20px",
}}
title={i18n.language}
/> 
    <MDBTextArea
        label={t('Description (German)')}
        value={event?.description?.find(d => d.language === 'de')?.text || ''}
        onChange={(e) => handleInputChange('description', e.target.value, 'de')}
    />
    </MDBCol>
    <MDBCol md="6">
    <ReactCountryFlag
countryCode="gb"
svg
style={{
width: "20px",
height: "20px",
}}
title={i18n.language}
/>
    <MDBTextArea
        label={t('Description (English)')}
        value={event?.description?.find(d => d.language === 'en')?.text || ''}
        onChange={(e) => handleInputChange('description', e.target.value, 'en')}
    />
    </MDBCol>
</MDBRow>
{/* Description End */}

<MDBRow className="mb-4">
    <MDBCol md="6">
    <MDBInput
        type="date"
        label={t('Start Date')}
        value={event?.startDate || ''}
        onChange={(e) => handleInputChange('startDate', e.target.value)}
    />
    </MDBCol>
    <MDBCol md="6">
    <MDBInput
        type="date"
        label={t('End Date')}
        value={event?.endDate || ''}
        onChange={(e) => handleInputChange('endDate', e.target.value)}
    />
    </MDBCol>
</MDBRow>

<MDBInput
    label={t('Status')}
    value={event?.status || ''}
    onChange={(e) => handleInputChange('status', e.target.value)}
    className="mb-4"
/>
<MDBInput
    label={t('Landing Page URL')}
    type="url"
    value={event?.landingPageUrl || ''}
    onChange={(e) => handleInputChange('landingPageUrl', e.target.value)}
    className="mb-4"
/>

<MDBRow className="mb-4">
    <MDBCol md="6">
    <MDBTextArea
        label={t('Booking Notice (English)')}
        value={event?.bookingNotice?.find(n => n.language === 'en')?.text || ''}
        onChange={(e) => handleInputChange('bookingNotice', e.target.value, 'en')}
    />
    </MDBCol>
    <MDBCol md="6">
    <MDBTextArea
        label={t('Booking Notice (German)')}
        value={event?.bookingNotice?.find(n => n.language === 'de')?.text || ''}
        onChange={(e) => handleInputChange('bookingNotice', e.target.value, 'de')}
    />
    </MDBCol>
</MDBRow>
<MDBTypography tag="h5">{t("Deadlines")}</MDBTypography>

<MDBRow className="mb-4">
    <MDBCol md="4">
    <MDBInput
        type="date"
        label={t('Booth equipment booking start date')}
        value={event?.startBoothEquipmentBooking || ''}
        onChange={(e) => handleInputChange('startBoothEquipmentBooking', e.target.value)}
    />
    </MDBCol>
    <MDBCol md="4">
    <MDBInput
        type="date"
        label={t('Booth equipment booking deadline')}
        value={event?.deadlineBoothEquipmentBooking || ''}
        onChange={(e) => handleInputChange('deadlineBoothEquipmentBooking', e.target.value)}
    />
    </MDBCol>
    <MDBCol md="4">
    <MDBInput
        type="date"
        label={t('Upload print data deadline')}
        value={event?.deadlineBoothEquipmentPrint || ''}
        onChange={(e) => handleInputChange('deadlineBoothEquipmentPrint', e.target.value)}
    />
    </MDBCol>
</MDBRow>

<MDBTypography tag="h5">{t("Lectures")}</MDBTypography>

<MDBRow className="mb-4">
    <MDBCol md="6">
    <MDBInput
        type="date"
        label={t('Lecture Registration Start Date')}
        value={event?.lectureRegistrationStartDate || ''}
        onChange={(e) => handleInputChange('lectureRegistrationStartDate', e.target.value)}
    />
    </MDBCol>
    <MDBCol md="6">
    <MDBInput
        type="date"
        label={t('Lecture Registration End Date')}
        value={event?.lectureRegistrationEndDate || ''}
        onChange={(e) => handleInputChange('lectureRegistrationEndDate', e.target.value)}
    />
    </MDBCol>
</MDBRow>


<MDBRow className="mb-4">
    <MDBCol md="6">
    <MDBTextArea
        label={t('Lectures Notice (English)')}
        value={event?.lecturesNotice?.find(n => n.language === 'en')?.text || ''}
        onChange={(e) => handleInputChange('lecturesNotice', e.target.value, 'en')}
    />
    </MDBCol>
    <MDBCol md="6">
    <MDBTextArea
        label={t('Lectures Notice (German)')}
        value={event?.lecturesNotice?.find(n => n.language === 'de')?.text || ''}
        onChange={(e) => handleInputChange('lecturesNotice', e.target.value, 'de')}
    />
    </MDBCol>
</MDBRow>

<MDBInput
    label={t('Available Lecture Types (comma-separated)')}
    value={event?.availableLectureTypes?.join(', ') || ''}
    onChange={(e) => handleInputChange('availableLectureTypes', e.target.value.split(',').map(type => type.trim()))}
    className="mb-4"
/>

<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
    <div className="mb-2 mb-lg-4">
     
    </div>
    <div className="w-100 text-center mb-2 mb-lg-4">
    <img
      src={event?.image?.fileName ? 
      "https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/" + event.image.fileName: "https://via.placeholder.com/600x400"}
      className="img-thumbnail"
      style={{ height: "200px", width: "400px", objectFit: "cover" }}
      alt='...' />
    </div>
    <MDBFile id='fileDE' onChange={e => handleSetImage(e.target.files[0])} />
   
  </MDBCol>
 
</MDBRow>
<MDBTypography tag="h5">{t('Floor Plan')}</MDBTypography>
{
    event?.floorPlan?.fileName && 
    <div className="text-center">
    <MDBBtn color="link" onClick={() => downloadFile(event.floorPlan.s3Path)} size="sm" className="mb-0">
    {event.floorPlan.fileName}
    </MDBBtn>
    <br />
    <MDBBtn color="link" style={{color: "red"}}  size="sm" onClick={() => removeFile(event.floorPlan.s3Path)}>
    {t("Remove")}
    </MDBBtn>
    </div>
    
}
<MDBFile id='file'
onChange={e => handleSetFile(e.target.files[0])}
/>
<MDBBtn onClick={handleSubmit} disabled={isLoading} className="mt-4 float-end">
  {isLoading ? <MDBSpinner size="sm" /> : eventId ? t('Update Event') : t('Create Event')}
</MDBBtn>
</MDBTabsPane>
  <MDBTabsPane open={activeTab === 'products'}>
  <MDBRow>
    
    <MDBCol size="6" xl="3">
    <MDBSelect label={t("Type")}
      onChange={type => setEventProductsTypeFilter(type.value)}
      value={eventProductsTypeFilter}
      data={
          [
              { text: t("All"), value: null },
              { text: t("Basic equipment"), value: "boothDefault" },
              { text: t("Display Print"), value: "display_print" },
              { text: t("Carpet"), value: "carpet" },
              { text: t("Furniture"), value: "furniture" },
              { text: t("Sanitary"), value: "sanitary" },
              { text: t("Compressed air"), value: "compressed_air" },
              { text: t("Internet - Cable"), value: "internet_cable" },
              { text: t("Internet - Wifi"), value: "internet_wifi" },
              { text: t("Electrical"), value: "electrical" },
          ]
      }
  />
    </MDBCol>
    <MDBCol size="6" xl="2">
    <MDBBtn outline onClick={() => setShowAddProductModal(true)}>{t('Select Products')}</MDBBtn>
    </MDBCol>
    <MDBCol size="6" xl="2" className="position-absolute" style={{right: 0}}>
    <MDBBtn outline onClick={() => handleSaveEventProducts()} disabled={isLoadingSaveEventProducts}>{t('Save products')}</MDBBtn>
    </MDBCol>
  </MDBRow>
  <MDBTable>
    <MDBTableHead>
      <tr>
        <th>{t('Type')}</th>
        <th>{t('SKU')}</th>
        <th>{t('Name')}</th>
        <th>{t('Price')}</th>
        <th>{t('Stock')}</th>
        <th>{t('Bookings')}</th>
      </tr>
    </MDBTableHead>
    <MDBTableBody>
      {eventProducts.filter(p => eventProductsTypeFilter ? p.product.subType === eventProductsTypeFilter : true).map(product => (
        <tr key={product.id}>
          <td>{product.product.subType}</td>
          <td>{product.product.sku}</td>
          <td>{product.product.name.find(t => t.language === i18n.language)?.text || product.product.name?.[0]?.text}</td>
          <td>
            <MDBInput type="number" step="0.01" value={product.price} onChange={(e) => handleEventProductChange(product.id, 'price', e.target.value)} disabled={isLoadingSaveEventProducts} />
          </td>
          <td>
            <MDBInput type="number" step="1" value={product.stock} onChange={(e) => handleEventProductChange(product.id, 'stock', e.target.value)} disabled={isLoadingSaveEventProducts} />
          </td>
          <td className="text-center"><span>0</span> <Link to={`/admin/product/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Show bookings")}</MDBBtn></Link></td>
        </tr>
      ))}
    </MDBTableBody>
  </MDBTable>
 

  </MDBTabsPane>
  <MDBTabsPane open={activeTab === 'carpet'}>
  <MDBRow className="mb-4">
    <MDBCol size="12" md="6">
      <MDBTypography tag="h5">{t("Available Carpets")}</MDBTypography>
      <MDBListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
        {carpets.map((carpet) => (
          <MDBListGroupItem key={carpet.id} className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div 
                style={{ 
                  width: '20px', 
                  height: '20px', 
                  backgroundColor: carpet.color, 
                  marginRight: '10px',
                  border: '1px solid #000'
                }} 
              />
              <span>{carpet.name.find(n => n.language === i18n.language)?.text || carpet.name[0].text}</span>
            </div>
            <MDBBtn 
              color="primary" 
              size="sm"
              onClick={() => addCarpetToEvent(carpet.id)}
              disabled={eventCarpets.some(ec => ec.eventCarpet.id === carpet.id)}
            >
              {t("Add")}
            </MDBBtn>
          </MDBListGroupItem>
        ))}
      </MDBListGroup>
    </MDBCol>
    <MDBCol size="12" md="6">
      <MDBTypography tag="h5">{t("Selected Carpets for Event")}</MDBTypography>
      <MDBListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
        {eventCarpets.map((eventCarpet) => (
          <MDBListGroupItem key={eventCarpet.id} className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div 
                style={{ 
                  width: '20px', 
                  height: '20px', 
                  backgroundColor: eventCarpet.eventCarpet.color, 
                  marginRight: '10px',
                  border: '1px solid #000'
                }} 
              />
              <span>{eventCarpet.eventCarpet.name.find(n => n.language === i18n.language)?.text || eventCarpet.eventCarpet.name[0].text}</span>
            </div>
            <MDBBtn 
              color="danger" 
              size="sm"
              onClick={() => removeCarpetFromEvent(eventCarpet.id)}
            >
              {t("Remove")}
            </MDBBtn>
          </MDBListGroupItem>
        ))}
      </MDBListGroup>
    </MDBCol>
  </MDBRow>
  

</MDBTabsPane>
</MDBTabsContent>
    

       
      </MDBCardBody>
    </MDBCard>

<MDBModal open={showAddProductModal} onClose={() => setShowAddProductModal(false)}>
  <MDBModalDialog centered size="xl">
    <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t('Add Product')}</MDBModalTitle>
        </MDBModalHeader>
        <MDBCardBody>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th>{t('Type')}</th>
                        <th>{t('SKU')}</th>
                        <th>{t('Name')}</th>
                        <th>{t('Price')}</th>
                        <th>{t('Stock')}</th>
                        <th>{t('Add')}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {products.sort((a, b) => a.subType.localeCompare(b.subType)).map(product => (
                        <tr key={product.id} className={eventProducts.find(p => p.product.id === product.id) ? 'text-muted' : ''}>
                            <td>{product.subType}</td>
                            <td>{product.sku}</td>
                            <td>{product.name.find(t => t.language === i18n.language)?.text || product.name?.[0]?.text}</td>
                            <td>{product.price}</td>
                            <td>{product.stock}</td>
                            <td className="text-center">
                              {eventProducts.find(p => p.product.id === product.id) ?
                              <MDBBtn
                              color='secondary'
                              size='sm'
                              outline
                              disabled={!eventProducts.find(p => p.product.id === product.id)}
                              onClick={() => removeProductFromEvent(eventProducts.find(p => p.product.id === product.id).id)}>{t('Remove')}</MDBBtn>
                              :
                              <MDBBtn
                              color='primary'
                              size='sm'
                              disabled={eventProducts.find(p => p.product.id === product.id)}
                              onClick={() => addProductToEvent(product.id)}>{t('Add')}</MDBBtn>
                              }

                                
                            </td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </MDBCardBody>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
</>
  );
};

export default EditEvent;