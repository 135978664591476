import React, { useEffect, useState } from 'react';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { MDBInput, MDBBtn, MDBRow, MDBCol, MDBCheckbox, MDBIcon, MDBCard, MDBCardBody, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBCardTitle, MDBFooter, MDBContainer } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import LangSelect from "../LangSelect";
import PhoneInput from "react-phone-input-2";
import { hotjar } from "react-hotjar";


const SignIn = ({setAuthRoute, setIsStepConfirm, setEmail, setPasswordSignUp}) => {
    const { t, i18n } = useTranslation();
    const [loginRegisterActive, setLoginRegisterActive] = useState('login');

    const [error, setError] = useState(null);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newAttributes, setNewAttributes] = useState([]);
    const [newAttributesValues, setNewAttributesValues] = useState({});

    // Detect if user is in booking process
    const [bookingProcess, setBookingProcess] = useState(false);
    const [bookingProcessData, setBookingProcessData] = useState({});

    const handleLoginRegisterClick = (value) => {
        setLoginRegisterActive(value);
        if(newPasswordRequired) setNewPasswordRequired(false);
    };

    useEffect(() => {
        let params = new URL(window.location.href).searchParams;
        const username = params.get('email');
        const password = params.get('password');
        if(username && password) {
            console.log("auto login", username, password);
            setUsername(username);
            setPassword(password);
           
            handleSubmit(null, username, password);
        }
        if(window.location.href.includes("checkout")) {
            setBookingProcess(true);
            setBookingProcessData({
                productName: params.get('productName'),
                
            });
        }
        if(window.location.href.includes("book-ticket")) {
          setBookingProcess(true);
          setBookingProcessData({
              productName: "Lounges 2024 Wien",
              productDescription: params.get('ticketType') === "default" ? "Visitor Ticket" : "Premium ticket",
          });
        }
    }, []);

  

  const handleSubmit = async (e, autoLoginUser, autoLoginPassword) => {
    if(e) e.preventDefault();
    try {
      hotjar.identify(autoLoginUser || username);
        const { isSignedIn, nextStep, error } = await signIn({ username: autoLoginUser || username, password: autoLoginPassword || password});
        console.log(isSignedIn, nextStep, error);
        if(nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
            setNewPasswordRequired(true);
            setNewAttributes(nextStep.missingAttributes);
        }
        if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
          setAuthRoute("signUp");
          setIsStepConfirm(true);
          setEmail(username);
          setPasswordSignUp(newPassword || password);
        }
        setError(null);
    } catch (error) {
      console.error("Error signin in", error);
      hotjar.event("Login failed")
      if(autoLoginUser || username) hotjar.identify(autoLoginUser || username);

      if(error === "Temporary password has expired and must be reset by an administrator.") {
        setError("Your password has expired. please use “Forgot password?” to create a new password.");
      } else {
        setError(error)
      }
      
    }
  };

  const [confirmSignInLoading, setConfirmSignInLoading] = useState(false);

  const handleConfirmSignIn = async () => {
    setConfirmSignInLoading(true);
    try {
      const user = await confirmSignIn({challengeResponse: newPassword, options: {
        userAttributes: newAttributesValues
      }});
        console.log(user);
        
      setError(null);
      //hotjar.identify(user.username);
      hotjar.event("Confirmed Sign In")
    } catch (error) {
        console.error(error);
        hotjar.event("Confirm Sign In failed")
        setError(error)
    }
    setConfirmSignInLoading(false);
  }



  return (
<>
{bookingProcess &&
<div className="d-flex justify-content-center" >
    <MDBCard className="col-md-8 col-xl-6 col-xxl-4 mt-1 mt-xl-3" style={{position: "absolute"}}>
        <MDBCardBody className="py-1 text-center">
        <MDBCardTitle>{t("Your booking:")}</MDBCardTitle>
            <div className='d-flex align-items-center w-100'>
            <div className='d-flex align-items-center text-start mx-auto'>
            <MDBIcon style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="shopping-cart" />
            <div className='ms-3'>
            <p className='fw-bold mb-1'>{t(bookingProcessData.productName)}</p>
            <p className='text-muted mb-0'>{t(bookingProcessData.productDescription)}</p>
            </div>
            </div>
            </div>
            <div className="alert alert-info py-2 mx-auto mt-1" style={{width: "fit-content"}}>
                <h6 className="mb-0">{t("Please sign in to continue")}</h6>
            </div>
        </MDBCardBody>

    </MDBCard>
</div>
}

   <MDBRow className="d-flex align-items-center m-0" style={{
    height: "100vh",
    backgroundImage: "url(https://iec-cp-public.s3.eu-central-1.amazonaws.com/2_2500x488px_lounges_ka_2024.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    }}>
    <MDBCol size="12" className="d-flex align-items-center justify-content-center" style={{height: "100%"}}>
    {/* <img
id="MDB-logo"
src="/logo_pos_cp.svg"
alt="Cleanroom Processes"
height={"65px"}
/> */}
        <MDBCard className="col-md-8 col-xl-6 col-xxl-4">
            <MDBCardBody>
            <MDBTabs pills justify className='mb-3'>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => handleLoginRegisterClick('login')}
                    active={loginRegisterActive === 'login'}
                >
                    Login
                </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signUp")}
                    active={loginRegisterActive === 'register'}
                >
                    Register
                </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>
                <MDBTabsPane open={loginRegisterActive === 'login'}>
                {newPasswordRequired ? 
                <>
                <h4>{t("Please set a new password")}</h4>
                <MDBInput className='mb-4' type='password' id='newPassword' label={t("New Password")} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                {newAttributes.length > 0 && <h6>{t("Please fill in the following fields")}</h6>}
                
                {newAttributes.map((attribute, index) => {
                    if(attribute === "phone_number") {
                        return (<>
                            <span>{t("Phone number")}</span>
                            <PhoneInput className="mb-4" key={index} country={'de'} enableSearch value={newAttributesValues[attribute]} onChange={(e) => setNewAttributesValues({...newAttributesValues, [attribute]: "+" + e})} />
                        </>)
                    } else {
                        return (
                        <MDBInput key={index} className='mb-4' type='text' id={attribute} label={t(attribute)} value={newAttributesValues[attribute]} onChange={(e) => setNewAttributesValues({...newAttributesValues, [attribute]: e.target.value})} />
                    )
                    }
                })}
                {
                    confirmSignInLoading ?
                    <MDBBtn className='mb-4' block disabled>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    </MDBBtn>
                    :
                    <MDBBtn onClick={handleConfirmSignIn} className='mb-4' block>Confirm Sign In</MDBBtn>
                }
                {error && <div className="alert alert-danger">{t(error.message)}</div>}
                </>
                :
                <form onSubmit={handleSubmit}>
                    

                    <br></br>

                    <MDBInput className='mb-4' type='email' id='email' data-hj-allow label='Email address' value={username ?? ""} onChange={(e) => setUsername(e.target.value)} />
                    <MDBInput className='mb-4' type='password' id='password' label='Password' value={password} onChange={(e) => setPassword(e.target.value)} />

                    <MDBBtn type='submit' className='' block>
                    {t("Sign in")}
                    </MDBBtn>
                    {error && <div className="alert alert-danger mt-2 text-center">{t(error.message)}</div>}
                    <MDBRow className='my-2'>
                    {/* <MDBCol className='d-flex justify-content-center'>
                        <MDBCheckbox id='form7Example3' label='Remember me' defaultChecked />
                    </MDBCol> */}
                    <MDBCol className="text-center">
                        <a href='#!' onClick={() => setAuthRoute("resetPassword")}>
                            {t("Forgot password?")}
                        </a>
                    </MDBCol>
                    </MDBRow>

                   
                    <div className='text-center mb-0 alert alert-info'>
                        <strong>{t("If you have an account in our old system x4Com, you can migrate it.")}</strong>
                        <br></br>
                    <MDBBtn href='#!' className='m-1' onClick={() => setAuthRoute("migrate")}>{t("Transfer your account")}</MDBBtn>
                    </div>

                    {/* <div className='text-center'>
                    <p>
                        Not a member? <a href='#!'>Register</a>
                    </p>
                    </div> */}
                   
                </form>
                }
                </MDBTabsPane>
               
            </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>
        
    </MDBCol>
    
    {/* <MDBCol lg="4" className="d-flex align-items-center justify-content-center" style={{
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        background: "linear-gradient(0deg, rgb(31 50 65 / 80%), rgb(31 50 65 / 39%)) center center / cover, url(https://iec-cp-public.s3.eu-central-1.amazonaws.com/AdobeStock_249789803.jpeg)  center center / cover",
        
        color: "white"
        }}>
        <div>
            <h1>Registrieren</h1>
            <p>
                Erstellen Sie ein Konto, um alle Vorteile zu nutzen.
            </p>
            <MDBBtn href='#!' color='light' className='m-1' style={{color: "black"}} onClick={() => setAuthRoute("signUp")}>Jetzt registrieren</MDBBtn>
        </div>
    </MDBCol> */}
   </MDBRow>
   <LangSelect style={{top: "0.5rem", right: "0.5rem", position: "absolute"}} />
   <MDBFooter className='text-center text-white' style={{backgroundColor: "#ed694b"}}>
        <section>
          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://www.facebook.com/profile.php?id=100090930616635'
            target="_blank"
            role='button'
          >
            <MDBIcon fab className='fab fa-facebook-f' />
          </MDBBtn>

        

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://www.instagram.com/lounges_cleanroom_processes/'
            target="_blank"
            role='button'
          >
            <MDBIcon fab className='fa-instagram' />
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://www.linkedin.com/company/digital365-cleanroom-and-processes/posts/?feedView=all'
            target="_blank"
            role='button'
          >
            <MDBIcon fab className='fa-linkedin' />
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://open.spotify.com/show/7kwLmAcFsRmBjXQPVfZF56'
            target="_blank"
            role='button'
          >
            <MDBIcon fab className='fa-spotify' />
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://www.youtube.com/@CleanroomProcesses/featured'
            target="_blank"
            role='button'
          >
            <MDBIcon size="lg" fab className='fa-youtube' />
          </MDBBtn>
        </section>


      <div className='text-center text-white pb-3'>
        © {new Date().getFullYear()}{' '}
        <a className='text-white' href='https://cleanroom-processes.de'>
            cleanroom-processes.de
        </a>
      </div>
    </MDBFooter>
   </>
  );
};

export default SignIn;