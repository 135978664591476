import { useEffect } from 'react';

export const useThemeDomain = () => {
  useEffect(() => {
    const hostname = window.location.hostname.toLowerCase();
    
    // Remove any existing theme classes first
    document.body.classList.remove('hd-theme');
    
    // Check for specific domains and apply corresponding theme
    if (hostname.includes('hygienic-design.com')) {
      document.body.classList.add('hd-theme');
    }
    // if (hostname.includes('localhost')) {
    //     document.body.classList.add('dark');
    // }
  }, []);
};