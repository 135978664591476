import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBInput, MDBRow, MDBSelect, MDBTypography } from "mdb-react-ui-kit"
import { useTranslation } from 'react-i18next'
import EventAndTicket from "../../pages-user/event-management/tickets/book-ticket-components/EventAndTicket"
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../App";
import { generateClient } from "aws-amplify/api";
import { getCompany, getEvent as getEventQuery } from "../../graphql/queries";
import { createTicket as createTicketMutation } from "../../graphql/mutations";
import getEventDays from "../../utils/getEventDays";
import { isoToGermanDate } from "../../utils/dateTools";
import { fullAddressSchema, billingAddressSchema, visitAdmin } from "../../validation/schemas/generic";
import useDebounce from "../../utils/debounce";


export default function BookTicketEmployee({employee, companyId, handleTicketBooked, toggleBookTicketModal, isOpen}) {
    const [events, setEvents] = useState([]);
    const [eventId, setEventId] = useState(null);
    const { t, i18n } = useTranslation()
    const topRef = useRef();
    const { user } = useContext(UserContext);
    const client = generateClient();
  
    const [event, setEvent] = useState(null);
    const [eventDays, setEventDays] = useState([]);
    const [params, setParams] = useState({
        visitorType: null,
        selectedDays: [],
        invitationCode: "ADMINBOOKING",
        ticketType: "default"
  
    });
    const [isLoading, setIsLoading] = useState(true);
  
    // User selections
    const [personalAddress, setPersonalAddress] = useState({});
    const [useProfileAddressForBilling, setUseProfileAddressForBilling] = useState(true);
    const [billingAddress, setBillingAddress] = useState({});
    
    // useEffect(() => {
    //     if(employee?.user) {
    //       setPersonalAddress({
    //         salutation: employee.user.salutation,
    //         title: employee.user.title,
    //         firstName: employee.user.firstName,
    //         lastName: employee.user.lastName,
    //         street: employee.contact?.address?.street || employee.user.contact?.address?.street || "",
    //         streetNumber: employee.contact?.address?.streetNumber || employee.user.contact?.address?.streetNumber || "",
    //         zip: employee.contact?.address?.zip || employee.user.contact?.address?.zip || "",
    //         city: employee.contact?.address?.city || employee.user.contact?.address?.city || "",
    //         countryCode: employee.contact?.address?.countryCode || employee.user.contact?.address?.countryCode || "",
    //         company: employee.contact?.address?.company || employee.user.contact?.address?.company || ""
    //       });
    //     }
    //   }, [employee?.user]);

    // Fetch event
    const getEvent = async () => {
        if(eventId) {
            try {
            const eventData = await client.graphql({
                query: getEventQuery,
                variables: { id: eventId } });
                setEvent(eventData.data.getEvent);
                setEventDays(getEventDays(eventData.data.getEvent));
            } catch (error) {
            console.error(error);
            if(error.data) {
                setEvent(error.data.getEvent);
                setEventDays(getEventDays(error.data.getEvent));
            }
            
            }
        }
    }

    const getEvents = async () => {
        try {
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents {
                    listEvents {
                        items {
                            id
                            name {
                                text
                                language
                            }
                            startDate
                            endDate
                            status
                        }
                    }
                }
                `,
                variables: {
                    filter: {
                        startDate: {
                            ge: new Date().toISOString().substring(0, 10)
                        }
                    }
                }
            });
            setEvents(events.data.listEvents.items.filter(event => event.status === "active" && new Date(event.endDate) >= new Date()));
            return events.data.listEvents.items;
        } catch (error) {
            console.log("error on fetching events", error);
        }
    }

    const reset = () => {
        setEvent(null);
        setEventDays([]);
        setParams({
            visitorType: null,
            selectedDays: [],
            ticketType: "default"
        });
        setPersonalAddress({});
        setUseProfileAddressForBilling(true);
        setBillingAddress({});
        setShowValidations({
            visit: false,
            personalAddress: false,
            billingAddress: false
        });
    }
    const getCompanyBillingAddress = async () => {
        try {
            const companyData = await client.graphql({
                query: /* GraphQL */ `
                query GetCompany($id: ID!) {
                  getCompany(id: $id) {
                    id
                    name
                    billingContact {
                      phone
                      mobile
                      fax
                      email
                      address {
                        street
                        street2
                        streetNumber
                        company
                        contactPerson
                        city
                        zip
                        countryCode
                      }
                    }
                    billingSettings {
                      orderNumberRequired
                    }

                  }
                }
              `,
                variables: { id: companyId }
            });
            setBillingAddress({...companyData.data.getCompany.billingContact.address, company: companyData.data.getCompany.name});
            setParams(old => ({...old, billingEmail: companyData.data.getCompany.billingContact.email}));

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if(isOpen) {
            topRef.current?.scrollIntoView({ behavior: "smooth" });
            getEvents();
            
            if(employee?.user) {
                setPersonalAddress({
                  salutation: employee.user.salutation,
                  title: employee.user.title,
                  firstName: employee.user.firstName,
                  lastName: employee.user.lastName,
                  street: employee.contact?.address?.street || employee.user.contact?.address?.street || "",
                  streetNumber: employee.contact?.address?.streetNumber || employee.user.contact?.address?.streetNumber || "",
                  zip: employee.contact?.address?.zip || employee.user.contact?.address?.zip || "",
                  city: employee.contact?.address?.city || employee.user.contact?.address?.city || "",
                  countryCode: employee.contact?.address?.countryCode || employee.user.contact?.address?.countryCode || "",
                  company: employee.contact?.address?.company || employee.user.contact?.address?.company || ""
                });
            }
            if(companyId) getCompanyBillingAddress();
        } else {
            reset();
        }
    }, [isOpen]);

    useEffect(() => {
        if(eventId) getEvent();
    }, [eventId]);

    
    // useEffect(() => {
    //     if(companyId) getCompanyBillingAddress();
    // }, [companyId]);

    const updatePersonalAddress = (event) => {
        const path = event.target.attributes.datapath.value;
        const value = event.target.value;
        setPersonalAddress({...personalAddress, [path]: value});
    }

    const updateBillingAddress = (event) => {
        const path = event.target.attributes.datapath.value;
        const value = event.target.value;
        setBillingAddress({...billingAddress, [path]: value});
    }

    // Create ticket booking
    const createTicket = async () => {
        try {
            const ticketData = await client.graphql({
                query: createTicketMutation,
                variables: {
                input: {
                    type: params.ticketType,
                    invitationCode: params.invitationCode,
                    visitorType: params.visitorType,
                    userTicketsId: employee.user.id,
                    eventDays: params.selectedDays, 
                    createdByUserId: user.id,
                    ticketCompanyId: companyId,
                    ticketEventId: eventId,
                    personalAddress: personalAddress,
                    firstName: personalAddress.firstName,
                    lastName: personalAddress.lastName,
                    companyName: personalAddress.company,
                    billingAddress: billingAddress,
                    orderNumber: params.orderNumber,
                    billingEmail: params.billingEmail
                }
                }
            });
            console.log("Created Ticket");
        //console.log(ticketData);
        } catch (error) {
        console.error(error);
        }
    }

    
    // Validation
    const [errors, setErrors] = useState([]);
  const [validations, setValidations] = useState({});
  const [showValidations, setShowValidations] = useState({
    visit: false,
    personalAddress: false,
    billingAddress: false
  });

  const debouncedPersonalAddress = useDebounce(personalAddress, 1000);
  const debouncedBillingAddress = useDebounce(billingAddress, 1000);
  const debouncedParams = useDebounce(params, 500);

  useEffect(() => {
    const personalProfile = fullAddressSchema.validate(debouncedPersonalAddress).map((validation) => validation.path);
    const billingProfile = billingAddressSchema.validate(debouncedBillingAddress).map((validation) => validation.path);
    const visit = visitAdmin.validate({...debouncedParams}).map((validation) => validation.path);
    setValidations({personalAddress: personalProfile, billingAddress: billingProfile, visit});
  }, [debouncedPersonalAddress, debouncedBillingAddress, debouncedParams]);


  const handleClickBookTicket = () => {
    if(validations?.visit?.length > 0 || validations?.personalAddress?.length > 0 || validations?.billingAddress?.length > 0) {
        //setErrors([t("Please fill out all required fields."), ...validations?.visit, ...validations?.personalAddress, ...validations?.billingAddress].filter(Boolean));
        setShowValidations({visit: true, personalAddress: true, billingAddress: true});
        return;
      }
    if(params.visitorType && params.selectedDays.length > 0 && params.ticketType) {
        createTicket().then(() => {
            handleTicketBooked();
            toggleBookTicketModal();
        });
    }
}

    return (
<div className="text-center" ref={topRef}>
    {
        !eventId ?
        <MDBSelect
            label={t("Event")}
            onChange={event => setEventId(event.value)}
            value={eventId}
            data={
                [
                    { text: t("All"), value: null },
                    ...events.map(event => ({ text: event.name.find(n => n.language === i18n.language)?.text || event.name[0]?.text, value: event.id }))
                ]
            }
        />
        :
 <> 

<MDBTypography tag='h2' className="mb-lg-4">{t("Event")}</MDBTypography>
<MDBRow>
    <MDBCol size="12" lg="6">
    {event &&
    <MDBCard className="col-lg-7 mx-auto">
    {event.image?.fileName &&
    <MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/eventImages/" + event.image.fileName} position='top' alt='...' />}
    <MDBCardBody>
        <MDBCardTitle>{event.description.find(d => d.language === i18n.language)?.text || event.description[0]?.text}</MDBCardTitle>
        <MDBCardText>
        {isoToGermanDate(event.startDate)} - {isoToGermanDate(event.endDate)}<br></br>
        {event.location?.address?.city}</MDBCardText>
    </MDBCardBody>
</MDBCard>}
    </MDBCol>
    <MDBCol size="12" lg="6">
    <MDBTypography tag='h2' className="my-lg-4">{t("Visiting days")}</MDBTypography>
    <div className="m-auto text-start" style={{width: "fit-content"}}>
        {eventDays && eventDays.map((day, index) => {
        return (
            <MDBCheckbox
            key={index}
            label={isoToGermanDate(day)}
            id={"day" + index}
            checked={params.selectedDays?.includes(day)}
            onChange={e => {
                if(e.target.checked) {
                setParams(old => ({...old, selectedDays: [...old.selectedDays, day]}))
                } else {
                setParams(old => ({...old, selectedDays: old.selectedDays.filter(d => d !== day)}))
                }
            }}
            />
        )}
        )}
    </div>
    </MDBCol>


</MDBRow>



<MDBRow className="my-2 my-xl-4 px-xl-5">
    <MDBTypography tag='h2' className="my-lg-4">{t("Visitor type")}</MDBTypography>
    <MDBCol>
    <MDBCard
        onClick={e => setParams(old => ({...old, visitorType: "staff"}))}
        shadow='0'
        background={params.visitorType === "staff" ? "success bg-opacity-10" : "white"}
        className={"h-100" + (params.visitorType === "staff" ? " border-success" : "")}
        style={{border: "1px solid", cursor: "pointer"}}
        >
            <MDBCardBody className='text-primary text-start'>
            <MDBCardTitle>{t("Booth staff")}</MDBCardTitle>
            <MDBCardText className="text-start">
            <ul>
            <li>{t("Early access 1 hour before the start of the event")}</li>
            <li>{t("Special lanyards to identify you as booth staff.")}</li>
            </ul>
            </MDBCardText>
            <div className="text-center" style={{ bottom: "0.5rem", position: "absolute", width: "-webkit-fill-available"}}>
            <strong>{t("Select")}</strong>
            </div>
            
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    <MDBCol>
    <MDBCard
        onClick={e => setParams(old => ({...old, visitorType: "visitor"}))}
        shadow='0'
        background={params.visitorType === "visitor" ? "success bg-opacity-10" : "white"}
        className={"h-100" + (params.visitorType === "visitor" ? " border-success" : "")}
        style={{border: "1px solid", cursor: "pointer"}}
        >
            <MDBCardBody className='text-primary text-start'>
            <MDBCardTitle>{t("Visitor")}</MDBCardTitle>
            <MDBCardText className="text-start">
            <ul>
            <li>{t("Entry to the event 30 minutes before the start of the event")}</li>
            </ul>
            </MDBCardText>
            <div className="text-center" style={{ bottom: "0.5rem", position: "absolute", width: "-webkit-fill-available"}}>
            <strong>{t("Select")}</strong>
            </div>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>

<MDBRow className="my-2 my-xl-4 px-xl-5">
    <MDBTypography tag='h2' className="my-lg-4">{t("Ticket")}</MDBTypography>
    <MDBCol>
    <MDBCard
        onClick={e => setParams(old => ({...old, ticketType: "default"}))}
        shadow='0'
        background={params.ticketType === "default" ? "success bg-opacity-10" : "white"}
        className={"h-100" + (params.ticketType === "default" ? " border-success" : "")}
        style={{border: "1px solid", cursor: "pointer"}}
        >
            <MDBCardBody className='text-primary text-start'>
            <MDBCardTitle>{t("Standard")}</MDBCardTitle>
            <MDBCardText className="text-start">
            <ul>
            <li>{t("Access to the event on the selected days")}</li>
            <li>{t("Visit the exhibition and participate in lectures and activities")}</li>
            <li>{t("Free food and drinks")}</li>
            </ul>
           
            </MDBCardText>
            {/* <div className="text-center" style={{ bottom: "0.5rem", position: "absolute", width: "-webkit-fill-available"}}>
            <strong>{t("Select")}</strong>
            </div> */}
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    {/* <MDBCol>
    <MDBCard
        onClick={e => setParams(old => ({...old, ticketType: "premium"}))}
        shadow='0'
        background={params.ticketType === "premium" ? "success bg-opacity-10" : "white"}
        className={"h-100" + (params.ticketType === "premium" ? " border-success" : "")}
        style={{border: "1px solid", cursor: "pointer"}}
        >
            <MDBCardBody className='text-primary text-start pb-1'>
            <MDBCardTitle>{t("Premium")}<span className="float-end">500€* <span className="text-danger fw-light text-decoration-line-through">1000€</span></span></MDBCardTitle>
            <MDBCardText className="text-start">
            <ul>
            <li>{t("Access to the event on the selected days")}</li>
            <li>{t("Over 200 technical, educational and marketing lectures from more than 200 exhibitors")}</li>
            <li>{t("Free food and drinks and participation in 2 special themed evenings")}</li>
            <li><strong>{t("Access to the recordings of all technical and educational lectures")}</strong></li>
            </ul>
            <span className="float-end mt-1">
            <small>*{t("Price for bookings and payment until 15.04.2024 and visitors that attend the event.")}</small>
            </span>
            </MDBCardText>
            <div className="text-center" style={{ bottom: "0.5rem", position: "absolute", width: "-webkit-fill-available"}}>
            <strong>{t("Select")}</strong>
            </div>
            </MDBCardBody>
        </MDBCard>
    </MDBCol> */}
</MDBRow>

<MDBTypography tag='h2' className="mb-lg-4">{t("Personal and billing information")}</MDBTypography>

<MDBRow>
    <MDBCol size="12" lg="6">
    <MDBCard className="mx-auto">
    <MDBCardBody>
    <h6>{t("Personal Information")}</h6>
        <MDBRow className="mb-2">
            <MDBCol size="12">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("company") ? "is-invalid" : ""} autoComplete="off" datapath="company" label={t("Company")} value={personalAddress.company || ""} onChange={updatePersonalAddress} />
            </MDBCol>
         </MDBRow>
         <MDBRow className="mb-2">
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("salutation") ? "is-invalid" : ""} autoComplete="off" datapath="salutation" label={t("Salutation")} value={personalAddress?.salutation || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("title") ? "is-invalid" : ""} autoComplete="off" datapath="title" label={t("Title")} value={personalAddress?.title || ""} onChange={updatePersonalAddress} />
            </MDBCol>
         </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("firstName") ? "is-invalid" : ""} autoComplete="off" datapath="firstName" label={t("First Name")} value={personalAddress?.firstName || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            
            <MDBCol size="12" md="6" className="mt-3 mt-md-0">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("lastName") ? "is-invalid" : ""} autoComplete="off" datapath="lastName" label={t("Last name")} value={personalAddress?.lastName || ""} onChange={updatePersonalAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="8">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("street") ? "is-invalid" : ""} autoComplete="off" datapath="street" label={t("Street")} value={personalAddress?.street || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            <MDBCol size="4">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("streetNumber") ? "is-invalid" : ""} autoComplete="off" datapath="streetNumber" label={t("Number")} value={personalAddress?.streetNumber || ""} onChange={updatePersonalAddress} />
            </MDBCol>
        </MDBRow>
        
        <MDBRow className="mb-2">
            
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("zip") ? "is-invalid" : ""} autoComplete="off" datapath="zip" label={t("Zip")} value={personalAddress?.zip || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("city") ? "is-invalid" : ""} autoComplete="off" datapath="city" label={t("City")} value={personalAddress?.city || ""} onChange={updatePersonalAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("countryCode") ? "is-invalid" : ""} autoComplete="off" datapath="countryCode" label={t("Country")} value={personalAddress?.countryCode || ""} onChange={updatePersonalAddress} />
            </MDBCol>
        </MDBRow>
    </MDBCardBody>
</MDBCard>
    </MDBCol>
    <MDBCol size="12" lg="6">
    <MDBCard className="mx-auto">
    <MDBCardBody>
        <h6>{t("Billing Information")}</h6>
         <MDBRow className="mb-2">
            <MDBCol size="12">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("company") ? "is-invalid" : ""} autoComplete="off" datapath="company" label={t("Company")} value={billingAddress?.company || ""} onChange={updateBillingAddress} />
            </MDBCol>
         </MDBRow>
        <MDBRow className="mb-2">
        
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("firstName") ? "is-invalid" : ""} autoComplete="off" datapath="firstName" label={t("First Name")} value={billingAddress?.firstName || ""} onChange={updateBillingAddress} />
            </MDBCol>
            
            <MDBCol size="12" md="6" className="mt-3 mt-md-0">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("lastName") ? "is-invalid" : ""} autoComplete="off" datapath="lastName" label={t("Last name")} value={billingAddress?.lastName || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="8">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("street") ? "is-invalid" : ""} autoComplete="off" datapath="street" label={t("Street")} value={billingAddress?.street || ""} onChange={updateBillingAddress} />
            </MDBCol>
            <MDBCol size="4">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("streetNumber") ? "is-invalid" : ""} autoComplete="off" datapath="streetNumber" label={t("Number")} value={billingAddress?.streetNumber || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        
        <MDBRow className="mb-2">
            
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("zip") ? "is-invalid" : ""} autoComplete="off" datapath="zip" label={t("Zip")} value={billingAddress?.zip || ""} onChange={updateBillingAddress} />
            </MDBCol>
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("city") ? "is-invalid" : ""} autoComplete="off" datapath="city" label={t("City")} value={billingAddress?.city || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("countryCode") ? "is-invalid" : ""} autoComplete="off" datapath="countryCode" label={t("Country")} value={billingAddress?.countryCode || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        <h6>Optional:</h6>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput size="sm" autoComplete="off" datapath="billingOrderNumber" label={t("Order number")} value={params?.orderNumber || ""} onChange={(e) => setParams(old => ({...old, orderNumber: e.target.value}))} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput size="sm" autoComplete="off" datapath="billingEmail" label={t("Billing email address")} value={params?.billingEmail || ""} onChange={(e) => setParams(old => ({...old, billingEmail: e.target.value}))} />
            </MDBCol>
        </MDBRow>
    </MDBCardBody>

</MDBCard>
    </MDBCol>
</MDBRow>
{
showValidations && (validations?.visit?.length > 0 || validations?.personalAddress?.length > 0 || validations?.billingAddress?.length > 0) && 
<div className="alert alert-danger my-2 text-start mx-auto" style={{width: "fit-content"}}>
    {
        showValidations && showValidations.visit && validations?.visit?.length > 0 && <p>{t("Please select at least one day to visit.")}</p>
    }
    {
        showValidations && showValidations.personalAddress && validations?.personalAddress?.length > 0 && <p>{t("Please fill out all required fields for the personal information.")}</p>
    }
    {
        showValidations && showValidations.billingAddress && validations?.billingAddress?.length > 0 && <p>{t("Please fill out all required fields for the billing information.")}</p>
    }
    {
        showValidations && params.visitorType === null && <p>{t("Please select a visitor type.")}</p>
    }
    {
        showValidations && !params.ticketType && <p>{t("Please select a ticket type.")}</p>
    }
    </div>
}
<div className="text-center mt-3">
    <MDBBtn color="primary" onClick={handleClickBookTicket}>{t("Book ticket")}</MDBBtn>
</div>
</>} 
</div>

    )
}
