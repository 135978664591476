import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBListGroup, MDBListGroupItem, MDBBtn } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';

const client = generateClient();

const Shop = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = [
    { name: 'Messestände', types: ['event_booth', 'event_booth_partner'] },
    { name: 'Veranstaltungen', types: ['ticket'] },
    { name: 'Marketing', types: ['marketing'] },
    { name: 'Academy', types: ['digital_access'] },
  ];

  const typeNames = {
    event_booth: 'Event booths',
    event_space: 'Event spaces',
    event_booth_partner: 'Event booths partner',
    event_equipment: 'Event equipment',
    marketing: 'Marketing',
    marketing_booth: 'Marketing Stand',
    digital_access: 'Digital Access',
    ticket: 'Tickets',
    discount: 'Discount',
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async (nextToken = null, allProducts = []) => {
    try {
      const productData = await client.graphql({
        query: `
          query ListProducts($nextToken: String) {
            listProducts(limit: 1000, nextToken: $nextToken) {
              items {
                id
                name {
                  text
                  language
                }
                type
                price
                image {
                  main {
                    fileName
                    alt
                  }
                }
              }
              nextToken
            }
          }
        `,
        variables: { nextToken }
      });

      const newProducts = productData.data.listProducts.items;
      const updatedProducts = [...allProducts, ...newProducts];

      if (productData.data.listProducts.nextToken) {
        // If there's a next token, fetch the next page
        return fetchProducts(productData.data.listProducts.nextToken, updatedProducts);
      } else {
        // No more pages, filter and set the products
        const filteredProducts = updatedProducts.filter(product => 
          categories.some(category => category.types.includes(product.type))
        );
        setProducts(filteredProducts);
        setFilteredProducts(filteredProducts);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    if (category) {
      const filtered = products.filter(product => category.types.includes(product.type));
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  };

  const handleAddToCart = (product) => {
    // Implementieren Sie hier die Logik zum Hinzufügen zum Warenkorb
    console.log('Added to cart:', product);
  };

  return (

    <MDBRow>
        <MDBCol md="3">
          <MDBListGroup>
            <MDBListGroupItem 
              action 
              active={selectedCategory === null}
              onClick={() => handleCategoryClick(null)}
            >
              Alle Produkte
            </MDBListGroupItem>
            {categories.map((category, index) => (
              <MDBListGroupItem 
                key={index} 
                action 
                active={selectedCategory === category}
                onClick={() => handleCategoryClick(category)}
              >
                {category.name}
              </MDBListGroupItem>
            ))}
          </MDBListGroup>
        </MDBCol>
        <MDBCol md="9">
          {filteredProducts.map((product) => (
            <MDBCard key={product.id} className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol md="3">
                    {product.image?.main?.fileName ? (
                      <MDBCardImage
                        src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/${product.image.main.fileName}`}
                        alt={product.image.main.alt || product.name.find(n => n.language === 'de')?.text || product.name[0]?.text}
                        fluid
                        style={{ maxHeight: "150px", objectFit: "contain" }}
                      />
                    ) : (
                      <MDBCardImage
                        src="https://via.placeholder.com/150"
                        alt="Placeholder"
                        fluid
                        style={{ maxHeight: "150px", objectFit: "contain" }}
                      />
                    )}
                  </MDBCol>
                  <MDBCol md="6">
                    <h5>{product.name.find(n => n.language === 'de')?.text || product.name[0]?.text}</h5>
                    <MDBCardText>
                      {product.shortDescription?.find(d => d.language === 'de')?.text || product.shortDescription?.[0]?.text || 'Keine Beschreibung verfügbar'}
                    </MDBCardText>
                    <MDBCardText>
                      {t(typeNames[product.type] || product.type)}
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol md="3" className="text-end">
                    <h5 className="mb-3">{product.price} €</h5>
                    <MDBBtn color="primary" onClick={() => handleAddToCart(product)}>
                      Zum Warenkorb hinzufügen
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          ))}
        </MDBCol>
      </MDBRow>
  );
};

export default Shop;