import { MDBBtn, MDBCheckbox, MDBInput, MDBTypography, MDBValidationItem } from "mdb-react-ui-kit"
import React, { useEffect, useState } from 'react'
import { isoToGermanDate } from "../../../../utils/dateTools"
import { useTranslation } from "react-i18next"

export default function Visit({params, setParams, eventDays, handleClickPrev, handleClickNext, validations, showValidations, setShowValidations}) {
    const { t, i18n } = useTranslation()

    

    const handleClickButton = () => { 
        if(validations?.visit?.length > 0) {
            setShowValidations(old => ({...old, visit: true}));
            return;
        } else {
            setShowValidations(old => ({...old, visit: false}));
        }

        if(validations?.visit?.length === 0) {
            handleClickNext();
        }
    
    }

  return (
<>
<MDBTypography tag='h2' className="mb-lg-3">{t("Invitation code")}</MDBTypography>
<p>
    {t("Enter your invitation code you received from a company or a partner. If you didn't receive an invitation code, you can use the code LOUNGES2024")}
</p>
<div style={{maxWidth: "400px"}} className="mx-auto">
        <MDBInput className={showValidations?.visit && validations?.visit?.includes("invitationCode") ? "is-invalid" : ""} label={t("Invitation code")} required value={params.invitationCode} name="invitationCode" onChange={e => {setParams(old => ({...old, [e.target.name]: e.target.value}))}} />
</div>

<MDBTypography tag='h2' className="my-lg-4">{t("Days")}</MDBTypography>
<div className="text-start mx-auto" style={{width: "fit-content"}}>
{eventDays && eventDays.map((day, index) => {
return (
    <MDBCheckbox
    key={index}
    className={showValidations?.visit && validations?.visit?.includes("selectedDays") ? "is-invalid" : ""}
    label={isoToGermanDate(day)}
    id={"day" + index}
    checked={params.selectedDays?.includes(day)}
    onChange={e => {
        if(e.target.checked) {
        setParams(old => ({...old, selectedDays: [...old.selectedDays, day].filter(Boolean)}))
        } else {
        setParams(old => ({...old, selectedDays: old.selectedDays.filter(d => d !== day).filter(Boolean)}))
        }
    }}
    />
)}
)}
</div>
<div className="text-center mt-4">
    <MDBBtn color="primary" outline className="me-3" onClick={handleClickPrev}>{t("Back")}</MDBBtn>
    <MDBBtn color="primary" onClick={handleClickButton}>{t("Next")}</MDBBtn>
</div>
</>
  )
}
