
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBRow } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { isoToGermanDate } from "../../../../utils/dateTools";
import { useState } from "react";


export default function Booking_Confirmation({user, event, params, personalAddress, useProfileAddressForBilling, setUseProfileAddressForBilling, 
    billingAddress, handleBookTicket, bookingInProgress, handleClickPrev, handleClickNext, errors}) {
    const { t, i18n } = useTranslation()

    const [tocAccepted, setTocAccepted] = useState(false);

    const handleClickButton = () => {
        if (tocAccepted) handleClickNext();
    }

  return (
<>
<h4>{t("Your ticket")}</h4>
<MDBRow>
    <MDBCol size="12" lg="6">
        {/* Event and Ticket */}
        <MDBCard className="mt-2 mt-lg-4">
            <MDBCardBody>
                <MDBCardTitle>{t("Your ticket")}</MDBCardTitle>
                <h6>{t("Event")}</h6>
                <MDBCardText>{event.description.find(t => t.language === i18n.language)?.text || event.description[0].text}</MDBCardText>
                {params.ticketType && (params.ticketType === "default" ? (
                    <MDBCard
                        shadow='0'
                        background={params.ticketType === "default" ? "success bg-opacity-10" : "white"}
                        className={"mx-lg-5 h-100" + (params.ticketType === "default" ? " border-success" : "")}
                        style={{border: "1px solid"}}
                    >
                        <MDBCardBody className='text-primary'>
                        <MDBCardTitle>{t("Visitor Ticket")}<span className="float-end">0€</span></MDBCardTitle>
                        <MDBCardText>
                        <ul>
                            <li>{t("Access to the event on the selected days")}</li>
                        </ul>
                        </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                )
                :
                (
                    <MDBCard
                        shadow='0'
                        background={params.ticketType === "premium" ? "success bg-opacity-10" : "white"}
                        className={"mx-lg-5 h-100" + (params.ticketType === "premium" ? " border-success" : "")}
                        style={{border: "1px solid"}}
                    >
                        <MDBCardBody className='text-primary'>
                        <MDBCardTitle>{t("Premium Ticket")}<span className="float-end">500€</span></MDBCardTitle>
                        <MDBCardText>
                        <ul>
                            <li>{t("Access to the event on the selected days")}</li>
                            <li>{t("Access to the recordings of all technical and educational lectures")}</li>
                        </ul>
                        </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                ))}
            <div className="mt-2 mt-lg-4">
            <h6>{t("Days")}</h6>
            {params.selectedDays?.map((day, index) => {
                return (
                    <span key={index}>{isoToGermanDate(day)}{index < params.selectedDays.length - 1 ? ", " : ""}</span>
                )
            })}
            </div>
            
            </MDBCardBody>
        </MDBCard>
        {/* Addresses */}
        <MDBCard className="mt-2 mt-lg-4">
            <MDBCardBody>
                <MDBRow>
                    <MDBCol size="12" lg="6">
                    <h6>{t("Personal information")}</h6>
                    {personalAddress?.company}
                    <br></br>
                    {personalAddress?.title ? personalAddress.title + " " : ""}{personalAddress?.firstName} {personalAddress?.middleName} {personalAddress?.lastName}
                    <br></br>
                    {personalAddress?.street} {personalAddress?.streetNumber}
                    <br></br>
                    {personalAddress?.zip} {personalAddress?.city}
                    <br></br>
                    {personalAddress?.countryCode}
                    </MDBCol>
                    <MDBCol size="12" lg="6">
                    <h6>{t("Billing address")}</h6>
                    {useProfileAddressForBilling ?
                        <>
                        {personalAddress?.company}
                        <br></br>
                        {personalAddress?.title ? personalAddress?.title + " " : ""}{personalAddress?.firstName} {personalAddress?.middleName} {personalAddress?.lastName}
                        <br></br>
                        {personalAddress?.street} {personalAddress?.streetNumber}
                        <br></br>
                        {personalAddress?.zip} {personalAddress?.city}
                        <br></br>
                        {personalAddress?.countryCode}
                        </>
                        :
                        <>
                        {billingAddress.company}
                        <br></br>
                        {billingAddress.firstName} {billingAddress.lastName}
                        <br></br>
                        {billingAddress.street} {billingAddress.streetNumber}
                        <br></br>
                        {billingAddress.zip} {billingAddress.city}
                        <br></br>
                        {billingAddress.countryCode}
                        </>
                    }
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    <MDBCol size="12" lg="6">
        {/* Preview */}
        <MDBCard className="mt-2 mt-lg-4">
            <MDBCardBody>
                <h4>{t("Badge preview")}</h4>
                <p>
                    {t("The badge will be printed on the event and will be available for you at the entrance.")}
                </p>
                <div className="text-center">
                    <img
                        src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/layout_badge_82x101mm_lounges_2024.jpg"
                        alt="Badge preview"
                        className="w-100"
                        style={{maxWidth: "300px"}}
                     />
                     <div className="position-absolute top-50 start-50" style={{transform: "translate(-50%, -50px)"}}>
                     <h4>Die Plattform der Branche</h4>
                     <h5>{user.title ? user.title + " " : ""}{user.firstName} {user.middleName} {user.lastName}</h5>
                     <h6>{user.contact?.address.company}</h6>
                     <MDBRow>
                        <MDBCol size="6">
                            <img src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/dummy_qr_code.png" alt="QR-Code" className="w-100" style={{maxWidth: "100px"}} />
                        </MDBCol>
                        <MDBCol size="6" className="mt-3">
                        {params.selectedDays?.map((day, index) => {
                            return (
                                <div>
                                <span style={{lineHeight: "1"}} key={index}>{isoToGermanDate(day).substring(0,6)}</span>
                                <br></br>
                                </div>
                            )
                        })}
                        </MDBCol>
                     </MDBRow>
                     </div>
                </div>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>
<MDBRow>
    <MDBCol size="12">
        <div className="mt-2 mt-lg-4 text-start mx-auto" style={{width: "fit-content"}}>
            <MDBCheckbox label={
                <>
                {t("I accept the ")}
                <a href="https://cleanroom-processes.de/agb/" target="_blank" rel="noreferrer">{t("terms and conditions")}</a>
                {t(" and the ")}
                <a href="https://cleanroom-processes.de/datenschutzerklarung/" target="_blank" rel="noreferrer">{t("privacy policy")}</a>
                </>}
                checked={tocAccepted}
                onChange={e => setTocAccepted(e.target.checked)}
                />
        </div>
    </MDBCol>
    <MDBCol size="12" className="mx-auto" style={{maxWidth: "500px"}}>
    {bookingInProgress ?
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    :
        <MDBBtn disabled={!tocAccepted} color="success" className="mt-2 mt-lg-4" style={{width: "100%"}} onClick={() => handleBookTicket()}>{t("Book now")}</MDBBtn>
    }
    {errors?.length > 0 && (
        <div className="alert alert-danger mt-2 mt-lg-4">
            {errors.map((error, index) => {
                return (
                    <p key={index}>{error}</p>
                )
            })}
        </div>
    )}
    </MDBCol>
</MDBRow>
</>
  )
}
