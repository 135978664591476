import { useState, useEffect } from 'react';
import { signUp } from 'aws-amplify/auth';
import { MDBInput, MDBBtn, MDBRow, MDBCol, MDBCheckbox, MDBIcon, MDBCard, MDBCardBody, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBFooter, MDBCardTitle } from 'mdb-react-ui-kit';
import { useTranslation } from "react-i18next";
import { generateClient, put } from 'aws-amplify/api';
import LangSelect from "../LangSelect";
import { hotjar } from "react-hotjar";



const client = generateClient();

const SignUp = ({authRoute, setAuthRoute, isStepConfirm, setIsStepConfirm}) => {
  const { t, i18n } = useTranslation();
  const [ newUser, setNewUser ] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: ''
  });
  const [ emailStatus, setEmailStatus ] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChangeInput = (e) => {
    setNewUser({
      ...newUser,
      [e.target.id]: e.target.value
    });
  };

  const checkEmailAvailable = async () => {
    if(isLoading) return;
    setIsLoading(true);
    hotjar.identify(newUser.email, {email: newUser.email})
    try {
      const restOperation = put({
      apiName: 'CPRESTAPI',
      path: '/user/checkuserformigration',
      options: {
       
        body: {
          email: newUser.email,
          redirectURI: window.location.href.replace(window.location.origin, "").replace("#!", "").replace("#", "")
        },
      }
    });
    const responseCode = (await restOperation.response).statusCode;
    if(responseCode === 200) {
      setEmailStatus("created");
      hotjar.event("Migration request successful");
    } else if (responseCode === 250) {
      setEmailStatus("exists");
      hotjar.event("Migration request failed: account already exists in new system");
    } else if (responseCode === 251) {
      setEmailStatus("noFoundOldSystem");
      hotjar.event("Migration request failed: account not found in old system");
    } else if (responseCode === 252) {
      setEmailStatus("alreadyMigrated");
      hotjar.event("Migration request failed: account already migrated to new system");
    }
      
    } catch (error) {
      console.error('error', error);
      setEmailStatus("error");
      setError(error);
      hotjar.event("Migration request failed: " + error.message);
    }
    setIsLoading(false);
  };

// Detect if user is in booking process
const [bookingProcess, setBookingProcess] = useState(false);
const [bookingProcessData, setBookingProcessData] = useState({});

  useEffect(() => {
    let params = new URL(window.location.href).searchParams;
    if(window.location.href.includes("checkout")) {
        setBookingProcess(true);
        setBookingProcessData({
            productName: params.get('productName'),
            
        });
    }
    if(window.location.href.includes("book-ticket")) {
      setBookingProcess(true);
      setBookingProcessData({
          productName: "Lounges 2024",
          productDescription: params.get('ticketType') === "default" ? "Visitor Ticket" : "Premium ticket",
      });
    }
}, []);





  return (
    <>
  {bookingProcess &&
<div className="d-flex justify-content-center" >
    <MDBCard className="col-md-8 col-xl-6 col-xxl-4 mt-1 mt-xl-3" style={{position: "absolute"}}>
        <MDBCardBody className="py-1 text-center">
        <MDBCardTitle>{t("Your booking:")}</MDBCardTitle>
            <div className='d-flex align-items-center w-100'>
            <div className='d-flex align-items-center text-start mx-auto'>
            <MDBIcon style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="shopping-cart" />
            <div className='ms-3'>
            <p className='fw-bold mb-1'>{t(bookingProcessData.productName)}</p>
            <p className='text-muted mb-0'>{t(bookingProcessData.productDescription)}</p>
            </div>
            </div>
            </div>
            <div className="alert alert-info py-2 mx-auto mt-1" style={{width: "fit-content"}}>
                <h6 className="mb-0">{t("Please sign in to continue")}</h6>
            </div>
        </MDBCardBody>

    </MDBCard>
</div>
}

   <MDBRow className="d-flex align-items-center m-0" style={{
    height: "100vh",
    backgroundImage: "url(https://iec-cp-public.s3.eu-central-1.amazonaws.com/2_2500x488px_lounges_ka_2024.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    }}>
    <MDBCol size="12" className="d-flex align-items-center justify-content-center" style={{height: "100%"}}>
        <MDBCard className="col-12 col-md-8 col-xl-6 col-xxl-4">
            <MDBCardBody>

            <MDBTabs pills justify className='mb-3'>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signIn")}
                >
                    Login
                </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signUp")}
                    active
                >
                    Register
                </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>          
                <MDBTabsPane open={true}>

               
                <h1>{t("Migrate Account")}</h1>
                <form>
                    
                    {emailStatus === null && <>
                      <MDBInput className='mb-4' type='email' data-hj-allow id='email' label='Email address' value={newUser.email} onChange={handleChangeInput} />
                      {isLoading ? <MDBBtn color="primary" disabled>{t("Checking...")}</MDBBtn> : <MDBBtn onClick={() => checkEmailAvailable()} className='mb-4' block>{t("Search for account")} </MDBBtn>}
                    </>}
                    
                    {emailStatus === "exists" &&
                    <div className="alert alert-warning text-center">
                      {t("You already have an account in our new platform, please try to log in")}
                      <MDBBtn className="mt-3" color="primary" onClick={() => setAuthRoute("signIn")}>{t("Login")}</MDBBtn>
                    </div>
                    }
                    {emailStatus === "noFoundOldSystem" &&
                    <div className="alert alert-warning text-center">
                      {t("No account found in old system. Please register a new account.")}
                      <MDBBtn className="mt-3" color="primary" onClick={() => setAuthRoute("signUp")}>{t("Register")}</MDBBtn>
                    </div>
                    }
                    {emailStatus === "alreadyMigrated" &&
                    <div className="alert alert-warning text-center">
                      {t("Account already migrated to new system. Please check your email to confirm your account.")}
                      <br></br>
                      {t("If you don't receive an email, please check your spam folder or contact us for further assistance.")} 
                    </div>
                    }
                    {emailStatus === "created" &&
                    <div className="alert alert-success text-center">
                      {t("Account found and migrated to new system.")} <strong>{t("Please check your email to confirm your account.")}</strong>
                      <br></br>
                      {t("If you don't receive an email, please check your spam folder or contact us for further assistance.")}
                      <br></br>
                    </div>
                  }
                  {emailStatus === "error" &&
                    <div className="alert alert-danger text-center">
                      {t("An error occured. Please contact our support.")}
                    </div>
                  }
                  {error && <div className="alert alert-danger text-center">{t(error.message)}</div>}
                </form>
                </MDBTabsPane>
            </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    
   </MDBRow>
   <LangSelect style={{bottom: "0px", right: "0px", position: "absolute"}} />
   <MDBFooter className='text-center text-white' style={{backgroundColor: "#ed694b"}}>
        <section>
          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://www.facebook.com/profile.php?id=100090930616635'
            target="_blank"
            role='button'
          >
            <MDBIcon fab className='fab fa-facebook-f' />
          </MDBBtn>

        

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://www.instagram.com/lounges_cleanroom_processes/'
            target="_blank"
            role='button'
          >
            <MDBIcon fab className='fa-instagram' />
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://www.linkedin.com/company/digital365-cleanroom-and-processes/posts/?feedView=all'
            target="_blank"
            role='button'
          >
            <MDBIcon fab className='fa-linkedin' />
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://open.spotify.com/show/7kwLmAcFsRmBjXQPVfZF56'
            target="_blank"
            role='button'
          >
            <MDBIcon fab className='fa-spotify' />
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-white m-1'
            href='https://www.youtube.com/@CleanroomProcesses/featured'
            target="_blank"
            role='button'
          >
            <MDBIcon size="lg" fab className='fa-youtube' />
          </MDBBtn>
        </section>


      <div className='text-center text-white pb-3'>
        © {new Date().getFullYear()}{' '}
        <a className='text-white' href='https://cleanroom-processes.de'>
            cleanroom-processes.de
        </a>
      </div>
    </MDBFooter>
   </>
  );
};

export default SignUp;