import { useState, useEffect, useContext } from "react"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCheckbox, MDBChip, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBSelect, MDBStepper, MDBStepperStep, MDBTypography, MDBValidationItem } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { generateClient } from 'aws-amplify/api';
import SelectTopics from "../../../../components-user/topics/SelectTopics";
import countryList from "react-select-country-list";


export default function PersonalInformation({
    user, params, setParams, useProfileAddressForBilling, setUseProfileAddressForBilling,
    billingAddress, setBillingAddress,personalAddress, setPersonalAddress, handleClickPrev, handleClickNext,
    validations, showValidations, setShowValidations}) {
    const client = generateClient();
    const { t, i18n } = useTranslation()

    const [profiles, setProfiles] = useState([]);

    const updatePersonalAddress = (event) => {
        const path = event.target.attributes.datapath.value;
        const value = event.target.value;
        setPersonalAddress({...personalAddress, [path]: value});
    }

    const updateBillingAddress = (event) => {
        const path = event.target.attributes.datapath.value;
        const value = event.target.value;
        setBillingAddress({...billingAddress, [path]: value});
    }


const handleClickButton = () => { 

    if(validations?.personalAddress?.length > 0) {
        setShowValidations(old => ({...old, personalAddress: true}));
    } else {
        setShowValidations(old => ({...old, personalAddress: false}));
        
    }
    
    if (!useProfileAddressForBilling && validations?.billingAddress?.length > 0) {
        setShowValidations(old => ({...old, billingAddress: true}));
    } else {
        setShowValidations(old => ({...old, billingAddress: false}));
    }

    if(validations?.personalAddress?.length === 0 && (useProfileAddressForBilling || (!useProfileAddressForBilling && validations?.billingAddress?.length === 0))) {
        handleClickNext();
    }

}

  return (
<>
 {/* Address data from user profile */}
 <h4>{t("Your personal information")}</h4>
<MDBCard className="mt-2 mt-lg-4 col-lg-10 col-xl-7 mx-auto">
    <MDBCardBody>
        
        <MDBRow className="mb-2">
            <MDBCol size="12">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("company") ? "is-invalid" : ""} autoComplete="off" datapath="company" label={t("Company")} value={personalAddress.company || ""} onChange={updatePersonalAddress} />
            </MDBCol>
         </MDBRow>
         <MDBRow className="mb-2">
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("salutation") ? "is-invalid" : ""} autoComplete="off" datapath="salutation" label={t("Salutation")} value={personalAddress?.salutation || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("title") ? "is-invalid" : ""} autoComplete="off" datapath="title" label={t("Title")} value={personalAddress?.title || ""} onChange={updatePersonalAddress} />
            </MDBCol>
         </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("firstName") ? "is-invalid" : ""} autoComplete="off" datapath="firstName" label={t("First Name")} value={personalAddress?.firstName || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            
            <MDBCol size="12" md="6" className="mt-3 mt-md-0">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("lastName") ? "is-invalid" : ""} autoComplete="off" datapath="lastName" label={t("Last name")} value={personalAddress?.lastName || ""} onChange={updatePersonalAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="8">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("street") ? "is-invalid" : ""} autoComplete="off" datapath="street" label={t("Street")} value={personalAddress?.street || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            <MDBCol size="4">
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("streetNumber") ? "is-invalid" : ""} autoComplete="off" datapath="streetNumber" label={t("Number")} value={personalAddress?.streetNumber || ""} onChange={updatePersonalAddress} />
            </MDBCol>
        </MDBRow>
        
        <MDBRow className="mb-2">
            
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("zip") ? "is-invalid" : ""} autoComplete="off" datapath="zip" label={t("Zip")} value={personalAddress?.zip || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("city") ? "is-invalid" : ""} autoComplete="off" datapath="city" label={t("City")} value={personalAddress?.city || ""} onChange={updatePersonalAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>

            <MDBSelect 
            inputClassName={showValidations?.personalAddress && validations?.personalAddress?.includes("countryCode") ? "is-invalid" : ""}
            search
            data={[
                {text: t("Select"), value: "", disabled: true, defaultSelected:true},
                ...countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
                const order = ['DE', 'AT', 'CH'];
                    const indexA = order.indexOf(a.value);
                const indexB = order.indexOf(b.value);
                if (indexA > -1 && indexB > -1) {
                    return indexA - indexB;
                } else if (indexA > -1) {
                    return -1;
                } else if (indexB > -1) {
                    return 1;
                }
                return a.value.localeCompare(b.value);
                }) || [{}] || [{}]
            ]}
            value={personalAddress?.countryCode || ""}
            size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")} onChange={e => updatePersonalAddress({
                target: {
                    attributes: {
                        datapath: {
                            value: "countryCode"
                        }
                    },
                    value: e?.value
                }
            })} />
                {/* <MDBInput size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")} value={personalAddress?.countryCode || ""} onChange={updatePersonalAddress} /> */}
            </MDBCol>
        </MDBRow>
        
        <h6>Optional:</h6>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("billingOrderNumber") ? "is-invalid" : ""} autoComplete="off" datapath="billingOrderNumber" label={t("Order number")} value={params?.orderNumber || ""} onChange={(e) => setParams(old => ({...old, orderNumber: e.target.value}))} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.personalAddress && validations?.personalAddress?.includes("billingEmail") ? "is-invalid" : ""} autoComplete="off" datapath="billingEmail" label={t("Billing email address")} value={params?.billingEmail || ""} onChange={(e) => setParams(old => ({...old, billingEmail: e.target.value}))} />
            </MDBCol>
        </MDBRow>
        {
            params.ticketType === "premium" && personalAddress.countryCode !== "DE" &&
            <>
            <h6>{t("Tax")} <MDBTypography style={{fontSize: "small"}} tag="span">({t("Only required for premium ticket if you are a company")})</MDBTypography>:</h6>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput required={params.ticketType === "premium" && personalAddress.countryCode !== "DE"} size="sm" autoComplete="off" datapath="taxId" label={t("Vat Id")} 
                value={params?.vatInfo?.vatNumber || ""} onChange={(e) => setParams(old => ({...old, vatInfo: {vatNumber: e.target.value}}))}
                 />
            </MDBCol>
        </MDBRow>
            </>
        }
    </MDBCardBody>
</MDBCard>

<div className="mt-2 mt-lg-4 text-start mx-auto" style={{width: "fit-content"}}>
    <MDBCheckbox label={t("Use the same address for billing")} checked={useProfileAddressForBilling} onChange={e => setUseProfileAddressForBilling(e.target.checked)} />
</div>


{ !useProfileAddressForBilling && 
<MDBCard className="mt-2 mt-lg-4 col-lg-10 col-xl-7 mx-auto">
    <MDBCardBody>
        <h6>{t("Billing Address")}</h6>
         <MDBRow className="mb-2">
            <MDBCol size="12">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("company") ? "is-invalid" : ""} autoComplete="off" datapath="company" label={t("Company")} value={billingAddress?.company || ""} onChange={updateBillingAddress} />
            </MDBCol>
         </MDBRow>
        <MDBRow className="mb-2">
        
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("firstName") ? "is-invalid" : ""} autoComplete="off" datapath="firstName" label={t("First Name")} value={billingAddress?.firstName || ""} onChange={updateBillingAddress} />
            </MDBCol>
            
            <MDBCol size="12" md="6" className="mt-3 mt-md-0">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("lastName") ? "is-invalid" : ""} autoComplete="off" datapath="lastName" label={t("Last name")} value={billingAddress?.lastName || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="8">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("street") ? "is-invalid" : ""} autoComplete="off" datapath="street" label={t("Street")} value={billingAddress?.street || ""} onChange={updateBillingAddress} />
            </MDBCol>
            <MDBCol size="4">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("streetNumber") ? "is-invalid" : ""} autoComplete="off" datapath="streetNumber" label={t("Number")} value={billingAddress?.streetNumber || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        
        <MDBRow className="mb-2">
            
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("zip") ? "is-invalid" : ""} autoComplete="off" datapath="zip" label={t("Zip")} value={billingAddress?.zip || ""} onChange={updateBillingAddress} />
            </MDBCol>
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("city") ? "is-invalid" : ""} autoComplete="off" datapath="city" label={t("City")} value={billingAddress?.city || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>
            <MDBSelect 
            inputClassName={showValidations?.billingAddress && validations?.billingAddress?.includes("countryCode") ? "is-invalid" : ""}
            search
            data={[
                {text: t("Select"), value: "", disabled: true, defaultSelected:true},
                ...countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
                const order = ['DE', 'AT', 'CH'];
                    const indexA = order.indexOf(a.value);
                const indexB = order.indexOf(b.value);
                if (indexA > -1 && indexB > -1) {
                    return indexA - indexB;
                } else if (indexA > -1) {
                    return -1;
                } else if (indexB > -1) {
                    return 1;
                }
                return a.value.localeCompare(b.value);
                }) || [{}] || [{}]]}
            value={billingAddress?.countryCode || ""}
            size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")} onChange={e => updateBillingAddress({
                target: {
                    attributes: {
                        datapath: {
                            value: "countryCode"
                        }
                    },
                    value: e?.value
                }
            })} />
                {/* <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("countryCode") ? "is-invalid" : ""} autoComplete="off" datapath="countryCode" label={t("Country")} value={billingAddress?.countryCode || ""} onChange={updateBillingAddress} /> */}
            </MDBCol>
        </MDBRow>
        {/* <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("countryCode") ? "is-invalid" : ""} autoComplete="off" datapath="countryCode" label={t("Country")} value={billingAddress?.countryCode || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow> */}
       
    </MDBCardBody>

</MDBCard> }

<div className="text-center mt-4">
    <MDBBtn color="primary" outline className="me-3" onClick={handleClickPrev}>{t("Back")}</MDBBtn>
    <MDBBtn color="primary" onClick={handleClickButton}>{t("Next")}</MDBBtn>
</div>
</>
  )
}
